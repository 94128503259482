import strings from '../../../../strings/strings.json';
import { Headline4, Headline5 } from 'components/Elements';
import { StepperFormProps } from 'components/stepper/stepperTypes';
import { TaskActivity } from 'models/inputModels/TaskActivity';
import { createErrorMessage, createGlobalErrorFromAPIError } from 'shared/error-messages';
import React from 'react';
import { FileDocument } from 'models/FileDocument';
import { FileMetaData } from 'components/fileUpload/strategies/strategies';
import useUploadRequest from 'hooks/useUploadRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { ErrorMessage } from 'components/forms/FormComponents';
import ActivityWizardButtonRow from '../ActivityWizardButtonRow';
import { VideoCard } from '../FileCard';
import { VideoSelectionComponent } from '../FileSelectionComponent';
import { styled } from 'styled-components';
import { sizes } from 'theme';
import { ResourcesInput } from '../ResourcesForm';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import ResourcesList from '../ResourcesList';
import { Resource } from 'models/Resources';
import { CreateTaskWithVideoRequest } from 'models/inputModels/CreateTaskRequest';
import { Activity } from 'models/Activity';

export default function TaskContentFormForVideo(props: StepperFormProps<TaskActivity>): JSX.Element {
    const { complete, newObject, setNewObject } = props;

    const context = React.useContext(EditCourseContext);

    const [errorsState, setErrors] = React.useState<string[]>([]);
    const [videoFromProvider, setVideoFromProvider] = React.useState<FileDocument>();
    const [videoFileMetaData, setVideoFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [linkModalOpen, setLinkModalOpen] = React.useState(false);
    const [resources, setResources] = React.useState<ResourcesInput[]>([]);
    const [disableCompleteButton, setDisableCompleteButton] = React.useState<boolean>(false);

    const selectVideoButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);
    const mounted = React.useRef(false);

    React.useEffect(() => {
        if (mounted.current === false) {
            mounted.current = true;
            selectVideoButtonRef.current?.focus();
            return;
        }
    }, []);

    React.useEffect(() => {
        if (newObject.videoFile || videoFromProvider) {
            setErrors([]);
        }
    }, [newObject.videoFile, videoFromProvider]);

    React.useEffect(() => {
        const resourcesInputs: ResourcesInput[] = newObject.resources.map((r) => {
            const resource: ResourcesInput = { id: r.id ?? '', title: r.title, url: r.url };
            return resource;
        });

        setResources(resourcesInputs);
    }, []); // eslint-disable-line

    React.useEffect(() => {
        if (resources.length <= 0) {
            return;
        }

        setNewObject({
            ...newObject,
            resources: resources.map((r) => {
                const resource: Resource = { id: r.id ?? '', title: r.title, url: r.url };
                return resource;
            }),
        });
    }, [resources]); //eslint-disable-line

    const { upload: uploadVideo, uploading: uploadingVideo } = useUploadRequest();
    const { modifyData: createTask, loading: creatingTask } = useModifyRequest(`activities/task/video`, 'POST');

    function updateErrors(apiErrors: APIError[]): void {
        let newErrors: string[] = [];

        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;

            if (field === 'global') {
                newErrors.push(createGlobalErrorFromAPIError(code));
            } else {
                newErrors.push(createErrorMessage(field, code));
            }
        });

        setErrors(newErrors);
    }

    async function submit() {
        let existingVideoId: string | null = null;
        let videoFilePath: string | null = null;
        let videoFileName: string | null = null;

        if (!videoFromLocal && !videoFromProvider) {
            setErrors(['Video is a required field']);
            return;
        }

        setDisableCompleteButton(true);

        if (videoFromProvider) {
            existingVideoId = videoFromProvider.id;
        } else if (videoFromLocal) {
            const uploadResponse = await uploadVideo(videoFromLocal);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                return;
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                return;
            }

            videoFilePath = uploadResponse.value.s3Key;
            videoFileName = uploadResponse.value.filename;
        }

        const request: CreateTaskWithVideoRequest = {
            title: newObject.title,
            description: newObject.description,
            durationInMins: newObject.durationInMins,
            moduleId: newObject.moduleId,
            resources: resources,
            existingVideoId: existingVideoId,
            videoFilename: videoFileName,
            videoFilepath: videoFilePath,
            videoTranscript: videoFileMetaData?.videoTranscript ?? null,
        };

        const response = await createTask(request);
        const { errors, value: task } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            setDisableCompleteButton(false);
            return;
        }

        const activityId = (task as Activity).id;
        const buildMethod = (task as Activity).buildMethod;
        context.setDataToShowGenerateContentModal({ activityId, buildMethod });
        await context.refetchCourse();
        complete && complete();
    }

    let uploadDate: Date = videoFromProvider ? videoFromProvider.dateUpdated : new Date();

    const videoFromLocal = newObject.videoFile;

    let videoName: string;
    if (videoFromLocal) videoName = videoFromLocal.name;
    else if (videoFromProvider) videoName = videoFromProvider.filename;
    else videoName = strings.fileUpload.browseFiles;

    const videoSelected = videoFromLocal || videoFromProvider;

    const setVideoFromLocal = (newFile: File | null | undefined) => {
        setNewObject({
            ...newObject,
            videoFile: newFile ?? null,
        });
        setVideoFromProvider(undefined);
    };

    const setVideoFromProviderFunc = (video: FileDocument) => {
        setNewObject({
            ...newObject,
            videoFile: null,
        });
        setVideoFromProvider(video);
    };

    const removeVideo = () => {
        setNewObject({
            ...newObject,
            videoFile: null,
        });
        setVideoFromProvider(undefined);
        setVideoFileMetaData(undefined);
        selectVideoButtonRef.current?.focus();
    };

    function addResource(newResource: ResourcesInput) {
        setResources([...resources, newResource]);
        addLinkButtonRef.current?.focus();
    }

    const deleteResource = (id: string | null, title: string, url: string, _: boolean) => {
        setResources(resources.filter((resource) => !(resource.title === title && resource.url === url)));
        addLinkButtonRef.current?.focus();
    };

    const closeLinksModal = () => {
        setLinkModalOpen(false);
        addLinkButtonRef.current?.focus();
    };

    return (
        <Container>
            <Headline4>{strings.taskContentForm.buildMethod.video}</Headline4>
            <VideoSection>
                {videoSelected && (
                    <VideoCard
                        fileName={videoName}
                        uploadDate={uploadDate}
                        removeFile={removeVideo}
                        disabled={uploadingVideo || creatingTask}
                    />
                )}
                <VideoSelectionComponent
                    setFileFromLocal={setVideoFromLocal}
                    setFileFromProvider={setVideoFromProviderFunc}
                    setFileFromLocalMetaData={setVideoFileMetaData}
                    disableButton={uploadingVideo || creatingTask}
                    selectFileButtonRef={selectVideoButtonRef}
                />
            </VideoSection>
            {errorsState.length > 0 &&
                errorsState.map((error, index) => (
                    <ErrorMessage id={`error-message-${index}`} key={index}>
                        {error}
                    </ErrorMessage>
                ))}
            <LinksSection>
                <Headline5>{strings.resourcesList.title}</Headline5>
                <ResourcesList
                    resources={resources}
                    deleteResource={deleteResource}
                    toggleModalOpen={() => setLinkModalOpen(!linkModalOpen)}
                    disabled={uploadingVideo || creatingTask}
                    addLinkButtonRef={addLinkButtonRef}
                />
            </LinksSection>
            {linkModalOpen && <LearningResourcesFormModal closeModal={closeLinksModal} addResource={addResource} />}
            <ActivityWizardButtonRow
                submitButtonTitle={strings.taskContentForm.submitButton}
                submitButtonAria={strings.taskContentForm.submitButtonAria}
                submitButtonFunction={submit}
                submitting={disableCompleteButton}
                showPreviousButton={false}
            />
        </Container>
    );
}

const LinksSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const VideoSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;
