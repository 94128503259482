import PrimaryButton from 'components/buttons/PrimaryButton';
import { Card, Headline4 } from 'components/Elements';
import FileUpload from 'components/fileUpload/FileUpload';
import { FileMetaData, FileType } from 'components/fileUpload/strategies/strategies';
import { NotificationType } from 'components/notifiers/Notification';
import { MainSectionWithGaps } from 'components/sections/MainSection';
import Spinner from 'components/Spinner';
import useModifyRequest from 'hooks/useModifyRequest';
import useUploadRequest from 'hooks/useUploadRequest';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import { logError } from 'lib/debug-helpers';
import { FileDocumentType, FileDocument } from 'models/FileDocument';
import { DocumentRequest } from 'models/inputModels/DocumentRequest';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import strings from '../../../../../strings/strings.json';
import Notification from 'components/notifiers/Notification';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { theme } from 'theme';
import Hint from 'components/Hint';

export type UploadVideoProps = {
    activityId: string;
    liveTrainingId: string;
    scheduledCourseId: string;
    s3FilePath: string | undefined;
    refetchData: Function;
};

export default function UploadVideo(uploadVideoProps: UploadVideoProps) {
    const { upload, uploading } = useUploadRequest();
    const navigate = useNavigate();

    const { modifyData: createDocument, loading: createLoading } = useModifyRequest('documents', 'POST');
    const { modifyData: attachVideoToActivity, loading: attachVideoLoading } = useModifyRequest(
        `activities/live-training/${uploadVideoProps.activityId}/video`,
        'PATCH',
    );

    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [fileValid, setFileValid] = React.useState<boolean>(false);
    const [fileMetaDataValid, setFileMetaDataValid] = React.useState<boolean>(false);
    const [fileMetaData, setFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [error, setError] = React.useState<string | null>(null);

    async function uploadFile() {
        setError(null);

        if (!tempFileFromLocal) {
            logError(strings.fileUploadPage.noFileText);
            setError(strings.fileUploadPage.noFileText);
            return;
        }

        if (!fileValid) return;
        if (!fileMetaDataValid) return;

        const uploadResponse = await upload(tempFileFromLocal);

        if (uploadResponse.errors && uploadResponse.errors.length > 0) {
            logError(uploadResponse.errors);
            setError(uploadResponse.errors[0].code);
            return;
        }

        if (!uploadResponse.value) {
            logError('Get presigned URL failed');
            setError('get presigned URL failed');
            return;
        }

        const response = await createDocument<DocumentRequest, FileDocument>({
            fileName: uploadResponse.value.filename,
            filePath: uploadResponse.value.s3Key,
            type: FileDocumentType.VIDEO,
            videoTranscript: fileMetaData?.videoTranscript,
            imageAltText: fileMetaData?.imageAltText,
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            setError(errors[0].code);
            return;
        }

        if (!response.value) {
            console.log('response no value');
            return;
        }

        await attachVideoToActivity({
            videoId: response.value.id,
            liveTrainingId: uploadVideoProps.liveTrainingId,
        });

        await uploadVideoProps.refetchData();
        await navigate(
            `/schedule/courses/${uploadVideoProps.scheduledCourseId}/live-training/${uploadVideoProps.liveTrainingId}`,
            {
                state: { activeTabId: 'upload-video-tab' },
            },
        );
    }

    return (
        <MainSectionWithGaps>
            {uploadVideoProps.s3FilePath ? (
                <>
                    <Headline4>{strings.liveTrainingDetailPage.uploadVideoTab.heading}</Headline4>
                    <Hint hint={strings.liveTrainingDetailPage.uploadVideoTab.hint} />
                    <video
                        controls
                        controlsList="nodownload"
                        src={`${DOCUMENTS_URL}/${uploadVideoProps.s3FilePath}`}
                        width="100%"
                    />
                </>
            ) : (
                <></>
            )}
            <Card>
                <FileUpload
                    localFile={tempFileFromLocal}
                    setLocalFile={(newFile: File | undefined) => {
                        setTempFileFromLocal(newFile);
                    }}
                    fileMetaData={fileMetaData}
                    setFileMetaData={(newFileMetaData: FileMetaData | undefined) => {
                        setFileMetaData(newFileMetaData);
                    }}
                    fileValid={fileValid}
                    setFileValid={(fileValid: boolean) => {
                        setFileValid(fileValid);
                    }}
                    fileMetaDataValid={fileMetaDataValid}
                    setFileMetaDataValid={(fileMetaDataValid: boolean) => {
                        setFileMetaDataValid(fileMetaDataValid);
                    }}
                    chosenFileType={FileType.VIDEO}
                />
            </Card>
            {error && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.fileUploadPage.errorTitle}
                    description={error ?? ''}
                    maxWidth={'100%'}
                />
            )}
            <AddFileButtonRow>
                <PrimaryButton
                    title={strings.liveTrainingDetailPage.uploadVideoTab.uploadButtonTitle}
                    aria={strings.liveTrainingDetailPage.uploadVideoTab.uploadButtonAria}
                    onClick={uploadFile}
                    disabled={uploading || createLoading || attachVideoLoading}
                    icon={
                        uploading || createLoading || attachVideoLoading ? (
                            <Spinner />
                        ) : (
                            <UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />
                        )
                    }
                />
            </AddFileButtonRow>
        </MainSectionWithGaps>
    );
}

const AddFileButtonRow = styled.div`
    display: flex;
    justify-content: end;
`;
