import * as React from 'react';
import strings from '../../../../strings/strings.json';
import useNeveForm from 'components/forms/NeveForm';
import useUploadRequest from 'hooks/useUploadRequest';
import styled from 'styled-components';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { Headline5, ButtonRowRight } from 'components/Elements';
import { FileMetaData } from 'components/fileUpload/strategies/strategies';
import { ErrorMessage, FormCard } from 'components/forms/FormComponents';
import SaveNotifier from 'components/notifiers/SaveNotifier';
import useModifyRequest from 'hooks/useModifyRequest';
import { logError } from 'lib/debug-helpers';
import { APIError } from 'lib/_api-helpers';
import { Activity } from 'models/Activity';
import { FileDocument } from 'models/FileDocument';
import { createGlobalErrorFromAPIError, createFieldErrorFromAPIError } from 'shared/error-messages';
import { sizes } from 'theme';
import useFormBlocker from 'components/forms/useFormBlocker';
import { ResourcesInput } from '../ResourcesForm';
import { VideoCard } from '../FileCard';
import { VideoSelectionComponent } from '../FileSelectionComponent';
import ResourcesList from '../ResourcesList';
import { LearningResourcesFormModal } from '../LearningResourcesFormModal';
import { TaskUpdateDetailsSection } from './TaskUpdateDetailsSection';
import { arraysEqual } from 'lib/arraysEqual';
import { GeneratedContentSection } from './GeneratedContentSection';
import GenerateContentModal, { GenerateModalState } from '../taskContentForms/GenerateContentModal';
import { CheckboxOption } from 'components/forms/Checkboxes';

type TaskInput = {
    title: string;
    description: string;
    durationInMins: number | null;
};

type TaskUpdateFormProps = {
    task: Activity;
    refetchActivity: () => Promise<void>;
};

export default function TaskUpdateFormForVideo({ task: taskProp, refetchActivity }: TaskUpdateFormProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [task, setActivity] = React.useState<Activity>(taskProp);
    const [globalErrorMessage, setGlobalErrorMessage] = React.useState<string>('');
    const [videoRequired, setVideoRequired] = React.useState<boolean>(false);
    const [resources, setResources] = React.useState<ResourcesInput[]>();
    const [modalOpen, setModalOpen] = React.useState(false);
    const [saved, setSaved] = React.useState<boolean>(false);

    const [localVideoToBeUploaded, setLocalVideoToBeUploaded] = React.useState<File | undefined | null>(null);
    const [providerVideoToBeUploaded, setProviderVideoToBeUploaded] = React.useState<FileDocument>();
    const [localVideoMetaData, setLocalVideoMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [videoCardComponentData, setVideoCardComponentData] = React.useState<FileCardComponentData | null>(
        taskProp.videoDocument
            ? { fileName: taskProp.videoDocument.filename, fileDate: taskProp.videoDocument.dateUpdated }
            : null,
    );

    const [generateModalState, setGenerateModalState] = React.useState<GenerateModalState>('hide');
    const [addText, setAddText] = React.useState<boolean>(false);

    const selectVideoButtonRef = React.useRef<HTMLButtonElement>(null);
    const addLinkButtonRef = React.useRef<HTMLButtonElement>(null);

    React.useEffect(() => {
        if (taskProp) {
            setActivity(taskProp);
            setResources(taskProp.resources);
            setGlobalErrorMessage('');
            setVideoRequired(false);
            setLocalVideoToBeUploaded(undefined);
            setProviderVideoToBeUploaded(undefined);
        }
    }, [taskProp]);

    React.useEffect(() => {
        let data: FileCardComponentData | null;

        if (localVideoToBeUploaded) {
            data = {
                fileName: localVideoToBeUploaded.name,
                fileDate: new Date(),
            };
        } else if (providerVideoToBeUploaded) {
            data = {
                fileName: providerVideoToBeUploaded.filename,
                fileDate: providerVideoToBeUploaded.dateUpdated,
            };
        } else if (task.videoDocument) {
            data = {
                fileName: task.videoDocument.filename,
                fileDate: task.videoDocument.dateUpdated,
            };
        } else {
            data = null;
        }

        setVideoCardComponentData(data);
    }, [task, localVideoToBeUploaded, providerVideoToBeUploaded]);

    React.useEffect(() => {
        setVideoRequired(!videoCardComponentData);
    }, [videoCardComponentData]);

    const { modifyData: updateTask, loading: updatingTask } = useModifyRequest(
        `activities/task/video/${task.id}`,
        'PUT',
    );

    const { modifyData: generateText, loading: generatingText } = useModifyRequest(
        `activities/task/${task.id}/generate/text`,
        'POST',
    );

    React.useEffect(() => {
        if (generatingText) {
            setGenerateModalState('submitting');
        }
    }, [generatingText]);

    const {
        register,
        handleSubmit,
        setError,
        watch,
        formState: { errors },
        reset,
    } = useNeveForm<TaskInput>({
        title: task?.title ?? ' ',
        description: task?.description ?? '',
        durationInMins: task?.durationInMins ?? null,
    });

    const [formDirty, setFormDirty] = React.useState<boolean>(false);

    useFormBlocker(formDirty, strings.courseEditorPage.unsavedChangesPrompt);

    const title = watch('title');
    const description = watch('description');
    const durationInMins = watch('durationInMins');

    React.useEffect(() => {
        const titleChanged = title !== taskProp.title;
        const descriptionChanged = description !== taskProp.description;
        const durationInMinsChanged = durationInMins != taskProp.durationInMins; // eslint-disable-line eqeqeq
        const videoChanged = videoCardComponentData?.fileName !== taskProp.videoDocument?.filename;
        const resourcesChanged = resources != undefined && !arraysEqual(resources, taskProp.resources); // eslint-disable-line eqeqeq

        const changed =
            titleChanged || descriptionChanged || durationInMinsChanged || resourcesChanged || videoChanged;

        setFormDirty(changed);
    }, [title, description, durationInMins, videoCardComponentData, resources]); // eslint-disable-line react-hooks/exhaustive-deps

    const { upload, uploading } = useUploadRequest();

    async function addResource(newResource: ResourcesInput) {
        setSaved(false);
        setResources([...(resources ?? []), newResource]);
        addLinkButtonRef.current?.focus();
    }

    function deleteResource(id: string | null, title: string, url: string, deleted: boolean): void {
        setSaved(false);

        if (id) {
            setResources(
                (resources ?? []).map((resource) => {
                    if (resource.id === id && deleted) {
                        return { title: resource.title, id: resource.id, url: resource.url, deleted };
                    } else {
                        return resource;
                    }
                }),
            );
        } else {
            setResources((resources ?? []).filter((resource) => !(resource.title === title && resource.url === url)));
        }

        if (saved) {
            setSaved(false);
        }

        addLinkButtonRef.current?.focus();
    }

    type SubmitFile = {
        filePath: string | null;
        fileName: string | null;
        id: string | null;
        videoTranscript: string | null;
    };

    async function uploadVideo(): Promise<SubmitFile | null> {
        if (localVideoToBeUploaded) {
            const uploadResponse = await upload(localVideoToBeUploaded);

            if (uploadResponse.errors && uploadResponse.errors.length > 0) {
                logError(uploadResponse.errors);
                updateErrors(uploadResponse.errors);
                throw new Error('Video upload failed');
            }

            if (!uploadResponse.value) {
                logError('Get presigned URL failed');
                updateErrors([{ field: 'global', code: 'get presigned URL failed' }]);
                throw new Error('Video upload failed');
            }

            return {
                id: null,
                filePath: uploadResponse.value.s3Key,
                fileName: uploadResponse.value.filename,
                videoTranscript: localVideoMetaData?.videoTranscript ?? null,
            };
        } else if (providerVideoToBeUploaded) {
            return {
                id: providerVideoToBeUploaded.id,
                filePath: null,
                fileName: null,
                videoTranscript: null,
            };
        } else if (task.videoDocument) {
            return {
                id: task.videoDocument.id,
                filePath: null,
                fileName: null,
                videoTranscript: null,
            };
        } else {
            return null;
        }
    }

    async function saveTask(formData: TaskInput) {
        setSaved(false);

        if (!videoCardComponentData) {
            selectVideoButtonRef.current?.focus();
            return;
        }

        let videoData: SubmitFile | null = null;
        try {
            videoData = await uploadVideo();
        } catch (error: any) {
            logError(error);
            return;
        }

        const videoHasChanged =
            task.videoDocument?.filename !== videoCardComponentData.fileName ||
            task.videoDocument?.dateUpdated !== videoCardComponentData.fileDate;

        const response = await updateTask<any, Activity>({
            title: formData.title,
            description: formData.description,
            durationInMins: formData.durationInMins ? +formData.durationInMins : null,
            resources,
            video: videoData,
            content: videoHasChanged ? '' : task.content, //video has changed so remove old transcript
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            updateErrors(errors);
            return;
        }

        reset(formData);
        await refetchActivity();
        await context.refetchCourse();
        setSaved(true);

        if (videoHasChanged) {
            setGenerateModalState('show');
        }
    }

    function updateErrors(apiErrors: APIError[]): void {
        apiErrors.forEach((apiError) => {
            const { field, code } = apiError;
            if (field === 'global') {
                return setGlobalErrorMessage(createGlobalErrorFromAPIError(code));
            }
            setError(field as keyof TaskInput, createFieldErrorFromAPIError(field, code));
        });
    }

    const removeVideo = () => {
        setSaved(false);
        setLocalVideoToBeUploaded(undefined);
        setProviderVideoToBeUploaded(undefined);
        setVideoCardComponentData(null);
        setActivity({ ...task, videoDocument: null });
        selectVideoButtonRef.current?.focus();
    };

    type FileCardComponentData = {
        fileName: string;
        fileDate: Date;
    };

    const generateContentOptions: CheckboxOption[] = [
        {
            name: strings.generateContentModal.options.videoToText,
            value: addText.toString(),
            inputProps: { onChange: () => setAddText(!addText) },
        },
    ];

    const generateContent = async () => {
        if (!addText) {
            setGenerateModalState('hide');
        } else {
            const response = await generateText({});
            setGenerateModalState(response.value === true ? 'success' : 'error');
        }
    };

    const closeGenerateContentModal = async () => {
        if (generateModalState === 'success') {
            await context.refetchCourse();
            await refetchActivity();
        }
        setGenerateModalState('hide');
    };

    return (
        <>
            {generateModalState !== 'hide' && (
                <GenerateContentModal
                    state={generateModalState}
                    close={closeGenerateContentModal}
                    generateContent={generateContent}
                    contentOptions={generateContentOptions}
                />
            )}
            <FormCardWithGaps onSubmit={handleSubmit(saveTask)} id="activityForm">
                <TaskUpdateDetailsSection
                    register={register}
                    watch={watch}
                    errors={errors}
                    onChange={() => saved && setSaved(false)}
                />
                <ResourceGroup>
                    <Headline5>{strings.learningMaterialsForm.addVideoTitle}</Headline5>
                    {videoCardComponentData && (
                        <VideoCard
                            fileName={videoCardComponentData.fileName}
                            uploadDate={videoCardComponentData.fileDate}
                            removeFile={removeVideo}
                            disabled={uploading || updatingTask}
                        />
                    )}
                    <VideoSelectionComponent
                        setFileFromLocal={(newFile) => {
                            setLocalVideoToBeUploaded(newFile);
                        }}
                        setFileFromProvider={setProviderVideoToBeUploaded}
                        setFileFromLocalMetaData={(newMetaData) => {
                            setLocalVideoMetaData(newMetaData);
                        }}
                        disableButton={uploading || updatingTask}
                        selectFileButtonRef={selectVideoButtonRef}
                    />
                    {videoRequired && <ErrorMessage>{strings.activityUpdatePage.videoRequired}</ErrorMessage>}
                </ResourceGroup>
                <GeneratedContentSection task={task} setTask={setActivity} />
                <ResourceGroup>
                    <Headline5>{strings.resourcesList.title}</Headline5>
                    <ResourcesList
                        resources={resources ?? []}
                        toggleModalOpen={() => {
                            setModalOpen(!modalOpen);
                            return saved && setSaved(false);
                        }}
                        deleteResource={deleteResource}
                        disabled={uploading || updatingTask}
                        addLinkButtonRef={addLinkButtonRef}
                    />
                </ResourceGroup>
                <ButtonRowRight>
                    <PrimaryButton
                        type="submit"
                        title={!uploading ? strings.activityForm.submitButton : strings.activityForm.uploadingFile}
                        aria={strings.activityForm.submitButtonAria}
                        disabled={uploading || updatingTask}
                        submitting={uploading || updatingTask}
                    />
                </ButtonRowRight>
                {saved && !formDirty && <SaveNotifier />}
                {globalErrorMessage && <ErrorMessage id="errorMessage">{globalErrorMessage}</ErrorMessage>}
            </FormCardWithGaps>
            {modalOpen && (
                <LearningResourcesFormModal closeModal={() => setModalOpen(false)} addResource={addResource} />
            )}
        </>
    );
}

const ResourceGroup = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
`;

const FormCardWithGaps = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    width: 100%;
`;
