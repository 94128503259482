import Alert, { AlertError } from 'components/alert/Alert';
import NavBackButton from 'components/buttons/NavBackButton';
import { Headline4, BodyLarge } from 'components/Elements';
import useNeveForm from 'components/forms/NeveForm';
import { ErrorNotifier } from 'components/notifiers/ErrorNotifier';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import useDeleteRequest from 'hooks/useDeleteRequest';
import useModifyRequest from 'hooks/useModifyRequest';
import { DOCUMENTS_URL } from 'lib/_api-helpers';
import React from 'react';
import { breakpoints, sizes, theme } from 'theme';
import { GenerateTypeContentComponent } from '../GenerateTypeContentComponent';
import strings from '../../../../../strings/strings.json';
import Hint from 'components/Hint';
import { TextAreaField } from 'components/forms/TextAreaField';
import { createErrorMessage, fieldErrorCodes, requiredFieldErrorMessage } from 'shared/error-messages';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import { GenerateType } from 'models/Activity';
import AudioPlayer from 'pages/learner/components/AudioPlayer';
import VideoPlayer from 'pages/learner/components/VideoPlayer';
import {
    ReviewGeneratedContentProps,
    Container,
    ContentCard,
    ApproveFormCard,
    BinIconContainer,
    ErrorRow,
} from './ReviewGeneratedSharedComponents';
import styled from 'styled-components';

export default function ReviewGeneratedTextComponent({ item, backButton }: ReviewGeneratedContentProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const [showError, setShowError] = React.useState<boolean>();
    const [openRejectAlert, setOpenRejectAlert] = React.useState(false);
    const [alertError, setAlertError] = React.useState<AlertError>();
    const [getTranscriptError, setGetTranscriptError] = React.useState<boolean>(false);

    type ApproveTextInput = {
        content: string;
    };

    const {
        register,
        handleSubmit,
        watch,
        setValue,
        formState: { errors },
    } = useNeveForm<ApproveTextInput>({
        content: '',
    });

    React.useEffect(() => {
        const getTranscript = async () => {
            try {
                const response = await fetch(`${DOCUMENTS_URL}/${item.taskTextContent}`);
                if (!response.ok) {
                    setGetTranscriptError(true);
                }

                const data = await response.json();
                const transcript: string | null = data?.results?.transcripts[0]?.transcript;
                if (!transcript) {
                    setGetTranscriptError(true);
                    return;
                }

                setValue('content', transcript);
            } catch (error) {
                setGetTranscriptError(true);
            }
        };

        getTranscript();
    }, [item.taskTextContent]); // eslint-disable-line

    const { modifyData: approveText, loading: approving } = useModifyRequest(
        `activities/task/${item.taskId}/approve-text`,
        'PUT',
    );

    const { deleteData: rejectStatus, deleteInProgress: rejecting } = useDeleteRequest(
        `generate-content-statuses/${item.id}`,
    );

    const approveClick = async (formData: ApproveTextInput) => {
        const { value, errors } = await approveText({
            content: formData.content,
        });

        if ((errors && errors.length > 0) || (!value as boolean)) {
            setShowError(true);
        } else {
            await context.refetchCourse();
            backButton();
        }
    };

    const rejectClick = async () => {
        const { value, errors } = await rejectStatus();

        if ((errors && errors.length > 0) || (value as boolean) === false) {
            const error: AlertError = {
                title: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorTitle,
                description: strings.courseAIGeneratedContentComponent.rejectAlert.alertErrorDescription,
            };
            setAlertError(error);
        } else {
            await context.refetchCourse();
        }
    };

    if (getTranscriptError) {
        <Container>
            <NavBackButton
                backButtonTitle={''}
                backButtonAria={strings.courseAIGeneratedContentComponent.backButtonAria}
                backButtonHref={''}
                buttonFunctionOverride={backButton}
            />
            <GenerateTypeContentComponent generateType={item.generateType} />
            <Headline4>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${item.taskOrder}: ${item.taskTitle}`}</Headline4>
            <BodyLarge>{strings.courseAIGeneratedContentComponent.contentTitle}</BodyLarge>
            <ErrorNotifier
                title={strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent.error.title}
                description={strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent.error.description}
            />
        </Container>;
    }

    const content = watch('content');
    const contentMaxLength = 10000;

    let hintText: string = '';
    let mediaPlayer: JSX.Element = <></>;

    switch (item.generateType) {
        case GenerateType.AUDIO_TO_TEXT:
            hintText = strings.courseAIGeneratedContentComponent.audioToTextHint;
            mediaPlayer = <AudioPlayer filepath={item.taskContentSource} />;
            break;
        case GenerateType.VIDEO_TO_TEXT:
            hintText = strings.courseAIGeneratedContentComponent.videoToTextHint;
            mediaPlayer = <VideoPlayer filepath={item.taskContentSource} />;
            break;
    }

    return (
        <>
            {openRejectAlert && (
                <Alert
                    buttonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButton}
                    ariaButtonText={strings.courseAIGeneratedContentComponent.rejectAlert.alertButtonAria}
                    alertText={strings.courseAIGeneratedContentComponent.rejectAlert.alertText}
                    closeAlert={() => setOpenRejectAlert(false)}
                    onSubmit={rejectClick}
                    submitting={rejecting}
                    error={alertError}
                />
            )}
            <Container>
                <NavBackButton
                    backButtonTitle={''}
                    backButtonAria={strings.courseAIGeneratedContentComponent.backButtonAria}
                    backButtonHref={''}
                    buttonFunctionOverride={backButton}
                />
                <GenerateTypeContentComponent generateType={item.generateType} />

                <Headline4>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${item.taskOrder}: ${item.taskTitle}`}</Headline4>
                <BodyLarge>{strings.courseAIGeneratedContentComponent.contentTitle}</BodyLarge>
                <ContentCard>
                    <Hint hint={hintText} />
                    {mediaPlayer}

                    <ApproveFormCard onSubmit={handleSubmit(approveClick)}>
                        <TextContentContainer>
                            <TextAreaField
                                fieldName={
                                    strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent
                                        .contentTextAreaField.name
                                }
                                maxLength={contentMaxLength}
                                charactersLeft={contentMaxLength - content.length}
                                aria={
                                    strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent
                                        .contentTextAreaField.aria
                                }
                                inputProps={register('content', {
                                    required: {
                                        value: true,
                                        message: requiredFieldErrorMessage(
                                            strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent
                                                .contentTextAreaField.name,
                                        ),
                                    },
                                    maxLength: {
                                        value: contentMaxLength,
                                        message: createErrorMessage(
                                            strings.courseAIGeneratedContentComponent.reviewGeneratedTextComponent
                                                .contentTextAreaField.name,
                                            fieldErrorCodes.maxLength,
                                        ),
                                    },
                                })}
                                errorMessage={errors.content?.message}
                                required
                                height={400}
                            />
                        </TextContentContainer>

                        <ButtonsRow>
                            <SecondaryButton
                                title={strings.courseAIGeneratedContentComponent.rejectButton.title}
                                aria={strings.courseAIGeneratedContentComponent.rejectButton.aria}
                                onClick={() => setOpenRejectAlert(true)}
                                icon={
                                    <BinIconContainer>
                                        <BinIcon size={IconSize.SMALL} colour={theme.secondaryButtonIconColorDanger} />
                                    </BinIconContainer>
                                }
                                alternateColour={SecondaryButtonColour.DANGER}
                                type={'button'}
                            />
                            <PrimaryButton
                                title={strings.courseAIGeneratedContentComponent.approveButton.title}
                                aria={strings.courseAIGeneratedContentComponent.approveButton.aria}
                                type={'submit'}
                                submitting={approving}
                            />
                        </ButtonsRow>
                    </ApproveFormCard>
                    {showError && (
                        <ErrorRow>
                            <ErrorNotifierSlim description={strings.courseAIGeneratedContentComponent.approveError} />
                        </ErrorRow>
                    )}
                </ContentCard>
            </Container>
        </>
    );
}

const ButtonsRow = styled.div`
    display: flex;
    justify-content: end;
    width: 100%;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;

const TextContentContainer = styled.div`
    width: 100%;
`;
