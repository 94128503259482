import { MainSectionWithGaps } from '../../../../components/sections/MainSection';
import SubpageHeader from '../../../../components/sections/SubpageHeader';
import strings from '../../../../strings/strings.json';
import { useLocation, useParams } from 'react-router-dom';
import useGetRequest from '../../../../hooks/useGetRequest';
import { ActivityMeeting, LocationType } from '../../../../models/ActivityMeeting';
import Skeleton from 'react-loading-skeleton';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from '../../../../theme';
import ScheduleIcon from '../../../../assets/icons/pathway/ScheduleIcon';
import TimeIcon from '../../../../assets/icons/pathway/TimeIcon';
import LinkIcon from '../../../../assets/icons/pathway/LinkIcon';
import LocationIcon from '../../../../assets/icons/controls/LocationIcon';
import SecondaryButton from '../../../../components/buttons/SecondaryButton';
import { useState } from 'react';
import Notification, { NotificationType } from '../../../../components/notifiers/Notification';
import { getLocaleTimeRangeString } from '../../../../lib/time-helpers';
import { ErrorMessage } from '../../../../components/forms/FormComponents';
import { InformationWithIconComponent } from '../../components/InformationWithIconComponent';
import { IconSize } from 'assets/icons/icon-sizes';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { TabDefinition } from 'components/tabs/Tabs';
import FileIcon from 'assets/icons/other/FileIcon';
import Attendance from './components/Attendance';
import VideoIcon from 'assets/icons/pathway/VideoIcon';
import UploadVideo from './components/UploadVideo';

type State = {
    activeTabId: string;
};

export default function LiveTrainingDetailPage(): JSX.Element {
    const { state } = useLocation();
    const { activeTabId } = (state as State) ?? {};
    const { liveTrainingId, courseId } = useParams();

    const {
        data: liveTrainingMeeting,
        loading,
        called,
        errors,
        refetchData,
    } = useGetRequest<ActivityMeeting>(
        `courses/schedule/${courseId}/live-training/${liveTrainingId}`,
        !liveTrainingId,
    );

    const [copied, setCopied] = useState<boolean>(false);

    function copyMeetingLink() {
        navigator.clipboard.writeText(liveTrainingMeeting?.joinLink ?? '');
        setCopied(true);
    }

    if (!called && !loading) {
        return <></>;
    }

    if (loading) {
        return (
            <>
                <SubpageHeader
                    title={strings.liveTrainingDetailPage.titleLoading}
                    shadedBackground
                    backButtonDetails={{
                        title: strings.liveTrainingDetailPage.backButtonTitle,
                        aria: strings.liveTrainingDetailPage.backButtonAria,
                        href: `/schedule/courses/${courseId}`,
                        navigateState: { state: { activeTabId: 'live-training' } },
                    }}
                />
                <InfoSection />
                <MainSectionWithGaps>
                    <Skeleton height={100} />
                </MainSectionWithGaps>
            </>
        );
    }

    if (errors && errors.length > 0) {
        return (
            <MainSectionWithGaps>
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.liveTrainingDetailPage.titleError}
                    description={strings.errorMessages.genericDetail}
                    big
                />
            </MainSectionWithGaps>
        );
    }

    if (!liveTrainingMeeting) {
        return <></>;
    }

    const attendanceTab: TabDefinition = {
        id: 'attendance-tab',
        name: strings.liveTrainingDetailPage.attendanceTab.title,
        icon: FileIcon,
        content: <Attendance />,
    };

    const uploadVideoTab: TabDefinition = {
        id: 'upload-video-tab',
        name: strings.liveTrainingDetailPage.uploadVideoTab.title,
        icon: VideoIcon,
        content: (
            <UploadVideo
                activityId={liveTrainingMeeting.activityId}
                scheduledCourseId={liveTrainingMeeting.scheduledCourseId}
                liveTrainingId={liveTrainingMeeting.id}
                s3FilePath={liveTrainingMeeting.videoDocument?.s3Filepath}
                refetchData={refetchData}
            />
        ),
    };

    return (
        <>
            <SubpageHeader
                title={liveTrainingMeeting?.activity.title ?? ''}
                shadedBackground
                backButtonDetails={{
                    title: strings.liveTrainingDetailPage.backButtonTitle,
                    aria: strings.liveTrainingDetailPage.backButtonAria,
                    href: `/schedule/courses/${courseId}`,
                    navigateState: { state: { activeTabId: 'live-training' } },
                }}
            />
            <InfoSection>
                <InformationSections>
                    <InformationWithIconComponent
                        icon={<ScheduleIcon colour={theme.textColour} />}
                        title={strings.liveTrainingDetailPage.information.startDate}
                        value={
                            liveTrainingMeeting.meetingDatetime
                                ? new Date(liveTrainingMeeting.meetingDatetime).toLocaleDateString()
                                : ''
                        }
                    />
                    <InformationWithIconComponent
                        icon={<TimeIcon colour={theme.textColour} />}
                        title={strings.liveTrainingDetailPage.information.time}
                        value={getLocaleTimeRangeString(
                            liveTrainingMeeting.meetingDatetime
                                ? new Date(liveTrainingMeeting.meetingDatetime)
                                : new Date(),
                            liveTrainingMeeting.activity.durationInMins ?? 1,
                        )}
                    />

                    {/* eslint-disable-next-line eqeqeq */}
                    {liveTrainingMeeting?.locationType == LocationType.ONLINE ? (
                        <LinkSection>
                            <InformationWithIconComponent
                                icon={
                                    <LinkIcon
                                        colour={theme.textColour}
                                        size={IconSize.MEDIUM}
                                        linkColour={theme.subpageHeaderBackground}
                                    />
                                }
                                title={strings.liveTrainingDetailPage.information.location}
                                value={liveTrainingMeeting.joinLink ?? strings.liveTrainingDetailPage.joinLinkNotFound}
                            />
                            {!liveTrainingMeeting.joinLink && (
                                <ErrorMessage>
                                    {strings.liveTrainingDetailPage.joinLinkNotFoundErrorDescription}
                                </ErrorMessage>
                            )}
                            {liveTrainingMeeting.joinLink && (
                                <SecondaryButton
                                    title={strings.liveTrainingDetailPage.copyButtonTitle}
                                    aria={strings.liveTrainingDetailPage.copyButtonAria}
                                    onClick={copyMeetingLink}
                                    autoFocus
                                />
                            )}
                            {copied && (
                                <Notification
                                    notificationType={NotificationType.SUCCESS}
                                    title={strings.liveTrainingDetailPage.copiedSuccess}
                                />
                            )}
                        </LinkSection>
                    ) : (
                        <InformationWithIconComponent
                            icon={<LocationIcon colour={theme.textColour} size={IconSize.MEDIUM} />}
                            title={strings.liveTrainingDetailPage.information.location}
                            value={liveTrainingMeeting.location ?? ''}
                        />
                    )}
                </InformationSections>
            </InfoSection>
            <TabComponentContainer>
                <TabStructureComponent tabs={[attendanceTab, uploadVideoTab]} initialActiveTabId={activeTabId} />
            </TabComponentContainer>
        </>
    );
}
const TabComponentContainer = styled.div`
    width: 100%;
    padding: 0 ${sizes.edgePadding};
    margin: ${sizes.spacingLg} auto;
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
`;

const InfoSection = styled.div`
    background: ${theme.courseBannerBackground};
    width: 100%;
    min-height: 100px;
`;

const InformationSections = styled.div`
    display: flex;
    align-items: center;
    row-gap: ${sizes.spacingSm};
    column-gap: ${sizes.spacingLg};
    margin: auto;
    margin-bottom: ${sizes.spacingMd};
    padding: 0 ${sizes.spacingMd};
    max-width: ${sizes.appMaxWidthWithoutPadding}px;
    flex-wrap: wrap;

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        align-items: unset;
    }
`;

const LinkSection = styled.div`
    display: flex;
    justify-content: flex-start;
    align-items: center;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingSm};
        align-items: unset;
        width: unset;
    }
`;
