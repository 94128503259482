import { Activity } from './Activity';
import { VideoDocument } from './FileDocument';

export enum LocationType {
    ONLINE = 'ONLINE',
    IN_PERSON = 'IN_PERSON',
}

export type ActivityMeeting = {
    id: string;
    activityId: string;
    meetingDatetime: Date;
    locationType: LocationType;
    joinLink?: string;
    location?: string;
    activity: Activity;
    createError?: string;
    attendees: Attendee[];
    scheduledCourseId: string;
    videoRecordingId?: string | null;
    videoDocument?: VideoDocument | null;
};

export type Attendee = {
    userId: string;
    firstName: string;
    lastName: string;
    email: string;
    imagePath: string;
    attended?: boolean;
};
