import { FormCard } from 'components/forms/FormComponents';
import styled from 'styled-components';
import { sizes, breakpoints, theme } from 'theme';
import { Card } from '../../FileCard';
import { TaskGenerateItem } from '../TaskGenerateItem';

export type ReviewGeneratedContentProps = {
    item: TaskGenerateItem;
    backButton: () => void;
};

export const BinIconContainer = styled.div`
    display: flex;
    align-items: center;
`;

export const EditAndApproveButtonsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        width: 100%;
    }
`;

export const ErrorRow = styled.div`
    display: flex;
    justify-content: end;
`;

export const ButtonsRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
    }
`;

export const ContentCard = styled(Card)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
    background-color: ${theme.cardBackground};
`;

export const Container = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

export const ApproveFormCard = styled(FormCard)`
    border: unset;
    box-shadow: unset;
    width: 100%;
`;

