import { BodyLarge } from 'components/Elements';
import React from 'react';
import styled from 'styled-components';
import { sizes, breakpoints, theme } from 'theme';
import AccordionPanelItem, { AccordionPanelItemProps } from './AccordionPanelItem';
import { AccordionPanelHeaderTitle } from './AccordionPanelTitle';
import strings from 'strings/strings.json';
import ArrowUpIcon from 'assets/icons/controls/ArrowUpIcon';
import ArrowDownIcon from 'assets/icons/controls/ArrowDownIcon';
import { animated, useSpring } from '@react-spring/web';

export type AccordionPanelProps = {
    headerTitle: string;
    headerDescription?: string;
    openPanel?: boolean;
    panelCompleted: boolean;
    completedItemsText: string;
    panelItems: AccordionPanelItemProps[];
};

export default function AccordionPanel({
    headerTitle,
    headerDescription,
    openPanel,
    panelCompleted,
    completedItemsText,
    panelItems,
}: AccordionPanelProps): JSX.Element {
    const [panelIsOpen, setPanelIsOpen] = React.useState<boolean>(false);
    const panelsRef = React.useRef<HTMLDivElement>(null);

    React.useEffect(() => {
        setPanelIsOpen(openPanel ?? false);
    }, [openPanel]);

    const { height } = useSpring({
        height: panelIsOpen ? `${panelsRef.current?.scrollHeight}px` : '0px',
    });

    return (
        <AccordionPanelContainer
            complete={panelCompleted}
            onClick={() => {
                setPanelIsOpen(!panelIsOpen);
            }}
            aria-label={panelIsOpen ? strings.accordion.closePanel : strings.accordion.openPanel}
        >
            <Panel>
                <PanelHeader>
                    <AccordionPanelHeaderTitle title={headerTitle} description={headerDescription} />
                    <CompletedItemsTextAndToggleContainer>
                        <CompletedItems $bold>{completedItemsText}</CompletedItems>
                        <ToggleContainer>{panelIsOpen ? <ArrowUpIcon /> : <ArrowDownIcon />}</ToggleContainer>
                    </CompletedItemsTextAndToggleContainer>
                </PanelHeader>
                <PanelItems style={{ height, overflow: 'hidden' }} ref={panelsRef}>
                    {panelItems.map((item, index) => {
                        return (
                            <AccordionPanelItem key={index} url={item.url} aria={item.aria} content={item.content} />
                        );
                    })}
                </PanelItems>
            </Panel>
        </AccordionPanelContainer>
    );
}

const PanelItems = styled(animated.div)`
    display: flex;
    flex-direction: column;
`;

const CompletedItemsTextAndToggleContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
`;

const CompletedItems = styled(BodyLarge)`
    width: max-content;
`;

const PanelHeader = styled.div`
    display: flex;
    gap: ${sizes.spacingLg};
    justify-content: space-between;
`;

const ToggleContainer = styled.div`
    margin-top: 2px;
`;

const Panel = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingRg};
`;

const AccordionPanelContainer = styled.button<{ complete?: boolean }>`
    cursor: pointer;
    padding: ${sizes.spacingMd};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);

    background-color: ${({ complete }) =>
        complete ? theme.learnerActivityCard.completedBackground : theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    border: 1px solid ${({ complete }) => (complete ? theme.notificationSuccessBorder : theme.cardBorder)};

    &:focus {
        background-color: ${theme.cardNavigation.backgroundFocused};
        border-radius: ${sizes.borderRadiusLg};
        border: 1px solid ${theme.cardNavigation.borderFocused};
    }
    &:hover {
        background-color: ${theme.secondaryButtonHoverBackgroundColour};
        border-radius: ${sizes.borderRadiusLg};
        border: 1px solid
            ${({ complete }) => (complete ? theme.notificationSuccessBorder : theme.secondaryButtonBorderColour)};
    }
    &:has(button:hover) {
        background-color: ${theme.cardBackground};
    }

    @media (max-width: ${breakpoints.sm}) {
        &:hover {
            background-color: ${({ complete }) =>
                complete ? theme.learnerActivityCard.completedBackground : theme.cardBackground};
            border-radius: ${sizes.borderRadiusLg};
            border: 1px solid ${({ complete }) => (complete ? theme.notificationSuccessBorder : theme.cardBorder)};
        }
    }
`;
