import PrimaryButton from 'components/buttons/PrimaryButton';
import { FormCardWithGaps } from 'components/forms/FormComponents';
import useNeveForm from 'components/forms/NeveForm';
import { TextAreaField } from 'components/forms/TextAreaField';
import TextField from 'components/forms/TextField';
import MainSection from 'components/sections/MainSection';
import SubpageHeader, { BackButtonDetails } from 'components/sections/SubpageHeader';
import useModifyRequest from 'hooks/useModifyRequest';
import {
    createErrorMessage,
    createGlobalErrorFromAPIError,
    fieldErrorCodes,
    globalErrorCodes,
    requiredFieldErrorMessage,
} from 'shared/error-messages';
import styled from 'styled-components';
import strings from '../../strings/strings.json';
import useGetRequest from 'hooks/useGetRequest';
import { BodyRegular, BodySmall, Card, Flex, FlexCol, Headline4 } from 'components/Elements';
import { logError } from 'lib/debug-helpers';
import React from 'react';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import { breakpoints, sizes } from 'theme';

type NotifyUsersFormInput = {
    header: string;
    body: string;
};

type UserDetails = {
    firstName: string;
    lastName: string;
    email: string;
};

export default function NotifyUsersPage() {
    const [errorMessage, setErrorMessage] = React.useState<string>('');
    const [success, setSuccess] = React.useState<boolean>(false);

    const { data: users } = useGetRequest<UserDetails[]>('admin/users');

    const { modifyData: sendNotifications, loading: sendingNotifications } = useModifyRequest(
        'admin/notify-users',
        'POST',
    );

    const {
        register,
        handleSubmit,
        formState: { errors },
        watch,
        reset,
    } = useNeveForm<NotifyUsersFormInput>({
        header: '',
        body: '',
    });

    async function onSubmit(formData: NotifyUsersFormInput) {
        const notificationResponse = await sendNotifications({ header: formData.header, body: formData.body });

        if (notificationResponse.errors) {
            const apiErrorMessage = createGlobalErrorFromAPIError(notificationResponse.errors[0].code);

            logError(apiErrorMessage);

            if (apiErrorMessage === globalErrorCodes.unknownError) {
                setErrorMessage(`${strings.notifyAllUsersPage.genericError}`);
            } else {
                setErrorMessage(apiErrorMessage);
            }

            return;
        }

        setSuccess(true);
        reset();
        return;
    }

    const backButtonDetails: BackButtonDetails = {
        title: `${strings.notifyAllUsersPage.backButtonDetails.title}`,
        aria: `${strings.notifyAllUsersPage.backButtonDetails.aria}`,
        href: '/admin/dashboard',
    };

    return (
        <>
            <SubpageHeader
                title={strings.notifyAllUsersPage.pageHeader}
                shadedBackground
                pageOverlap
                backButtonDetails={backButtonDetails}
            />
            <MainSection>
                <CardContainer>
                    <StyledFormCard onSubmit={handleSubmit(onSubmit)}>
                        <TextField
                            fieldName={strings.notifyAllUsersPage.messageHeaderTextField.inputLabel}
                            labelText={strings.notifyAllUsersPage.messageHeaderTextField.inputLabel}
                            inputAria={strings.notifyAllUsersPage.messageHeaderTextField.inputAria}
                            inputProps={register('header', {
                                required: {
                                    value: true,
                                    message: requiredFieldErrorMessage(
                                        `${strings.notifyAllUsersPage.messageHeaderTextField.requiredFieldErrorMessage}`,
                                    ),
                                },
                            })}
                            errorMessage={errors.header?.message}
                            maxLength={100}
                        />
                        <TextAreaField
                            fieldName={strings.notifyAllUsersPage.messageBodyInputField.inputLabel}
                            aria={strings.notifyAllUsersPage.messageBodyInputField.inputAria}
                            charactersLeft={1000 - watch('body').length}
                            inputProps={register('body', {
                                required: {
                                    value: true,
                                    message: requiredFieldErrorMessage(
                                        `${strings.notifyAllUsersPage.messageBodyInputField.requiredFieldErrorMessage}`,
                                    ),
                                },
                                maxLength: {
                                    value: 1000,
                                    message: createErrorMessage(
                                        `${strings.notifyAllUsersPage.messageBodyInputField.inputLabel}`,
                                        fieldErrorCodes.maxLength,
                                    ),
                                },
                            })}
                            errorMessage={errors.body?.message}
                            maxLength={1000}
                        />
                        {success && (
                            <Notification
                                notificationType={NotificationType.SUCCESS}
                                title={strings.notifyAllUsersPage.notificationSuccessMessage}
                                maxWidth="1270"
                            />
                        )}
                        {errorMessage && (
                            <Notification
                                notificationType={NotificationType.DANGER}
                                title={errorMessage}
                                maxWidth="1270"
                            />
                        )}
                        <ButtonContainer>
                            <PrimaryButton
                                title={strings.notifyAllUsersPage.submitButton.title}
                                aria={strings.notifyAllUsersPage.submitButton.aria}
                                type="submit"
                                submitting={sendingNotifications}
                            />
                        </ButtonContainer>
                    </StyledFormCard>
                    <UserListCard>
                        <Headline4>{strings.notifyAllUsersPage.allUsersCard.title}</Headline4>
                        {users ? (
                            users.map((user) => {
                                return (
                                    <FlexCol>
                                        <BodyRegular $bold>
                                            {user.firstName} {user.lastName}
                                        </BodyRegular>
                                        <BodySmall>{user.email}</BodySmall>
                                    </FlexCol>
                                );
                            })
                        ) : (
                            <Notification
                                notificationType={NotificationType.DANGER}
                                title={strings.notifyAllUsersPage.allUsersCard.errorMessageTitle}
                                description={strings.notifyAllUsersPage.allUsersCard.errorMessageDescription}
                                maxWidth="300px"
                            />
                        )}
                    </UserListCard>
                </CardContainer>
            </MainSection>
        </>
    );
}

const CardContainer = styled.div`
    display: flex;
    justify-content: space-between;
    gap: ${sizes.spacingLg};
    max-width: 100%;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
    }
`;

const StyledFormCard = styled(FormCardWithGaps)`
    max-height: min-content;
    flex-grow: 1;
`;

const UserListCard = styled(Card)`
    width: fit-content;
    height: fit-content;
    display: flex;
    flex-direction: column;
`;

const ButtonContainer = styled(Flex)`
    justify-content: end;
    align-items: center;
`;
