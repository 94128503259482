import TickIcon from 'assets/icons/controls/TickIcon';
import TimeIcon from 'assets/icons/pathway/TimeIcon';
import { Headline3 } from 'components/Elements';
import { TabDefinition } from 'components/tabs/Tabs';
import TabStructureComponent from 'components/tabs/TabStructureComponent';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import React from 'react';
import { styled } from 'styled-components';
import { sizes } from 'theme';
import strings from '../../../../strings/strings.json';
import BetaLabel from '../BetaLabel';
import ReviewGeneratedAudioComponent from './reviewGeneratedContentComponents/ReviewGeneratedAudioComponent';
import ReviewGeneratedTextComponent from './reviewGeneratedContentComponents/ReviewGeneratedTextComponent';
import { ModuleItem } from './ModuleItem';
import CompletedGeneratedContentTab from './tabs/CompletedGeneratedContentTab';
import QueuedGeneratedContentTab from './tabs/QueuedGeneratedContentTab';
import { TaskGenerateItem } from './TaskGenerateItem';
import { ActivityType, GenerateType } from 'models/Activity';
import { CourseGeneratedContentIsApproved } from 'models/Course';
import Notification, { NotificationType } from 'components/notifiers/Notification';

type CourseAIGeneratedContentComponentProps = {
    showIncompleteStepsWarning?: boolean;
};

export default function CourseAIGeneratedContentComponent({
    showIncompleteStepsWarning,
}: CourseAIGeneratedContentComponentProps): JSX.Element {
    const context = React.useContext(EditCourseContext);

    const [taskIdToReview, setTaskIdToReview] = React.useState<string>('');

    const modulesWithGeneratedContent: ModuleItem[] = [];
    const modulesWithQueuedContent: ModuleItem[] = [];

    context
        .course!.modules.sort((moduleA, moduleB) => {
            return moduleA.order - moduleB.order;
        })
        .forEach((module) => {
            const generatedContentItems: TaskGenerateItem[] = [];
            const queuedContentItems: TaskGenerateItem[] = [];

            module.activities
                .filter((activity) => activity.type === ActivityType.TASK)
                .sort((taskA, taskB) => {
                    return taskA.order - taskB.order;
                })
                .forEach((task) => {
                    if (!task.generateContentStatuses) return;

                    task.generateContentStatuses.forEach((status) => {
                        let taskTextContent = '';
                        let taskContentSource = '';

                        switch (status.generateType) {
                            default:
                            case GenerateType.TEXT_TO_AUDIO:
                                taskTextContent = task.content;
                                taskContentSource = status.s3Filepath;
                                break;
                            case GenerateType.AUDIO_TO_TEXT:
                                taskTextContent = status.s3Filepath;
                                taskContentSource = task.audioDocument?.s3Filepath ?? '';
                                break;
                            case GenerateType.VIDEO_TO_TEXT:
                                taskTextContent = status.s3Filepath;
                                taskContentSource = task.videoDocument?.s3Filepath ?? '';
                                break;
                        }

                        const taskItem: TaskGenerateItem = {
                            taskId: task.id,
                            taskOrder: task.order,
                            taskTitle: task.title,
                            taskTextContent,
                            taskContentSource,
                            id: status.id,
                            generateType: status.generateType,
                            dateCreated: status.dateCreated,
                            dateUpdated: status.dateUpdated,
                            dateGenerated: status.dateGenerated,
                            s3Filepath: status.s3Filepath,
                            dateErrored: status.dateErrored,
                            error: status.error,
                            dateApproved: status.dateApproved,
                        };

                        taskItem.dateGenerated || taskItem.dateErrored
                            ? generatedContentItems.push(taskItem)
                            : queuedContentItems.push(taskItem);
                    });
                });

            if (generatedContentItems.length > 0) {
                modulesWithGeneratedContent.push({
                    moduleOrder: module.order,
                    moduleTitle: module.title,
                    taskGenerateItems: generatedContentItems,
                });
            }

            if (queuedContentItems.length > 0) {
                modulesWithQueuedContent.push({
                    moduleOrder: module.order,
                    moduleTitle: module.title,
                    taskGenerateItems: queuedContentItems,
                });
            }
        });

    const completedTab: TabDefinition = {
        id: 'completed-tab',
        name: strings.courseAIGeneratedContentComponent.tab.completedTitle,
        icon: TickIcon,
        content: (
            <CompletedGeneratedContentTab
                moduleItems={modulesWithGeneratedContent}
                setTaskIdToReview={setTaskIdToReview}
            />
        ),
    };

    const queuedTab: TabDefinition = {
        id: 'queued-tab',
        name: strings.courseAIGeneratedContentComponent.tab.queuedTitle,
        icon: TimeIcon,
        content: <QueuedGeneratedContentTab moduleItems={modulesWithQueuedContent} />,
    };

    if (taskIdToReview) {
        const taskContent = modulesWithGeneratedContent
            .flatMap((module) => module.taskGenerateItems)
            .find((task) => task.id === taskIdToReview);

        if (taskContent) {
            switch (taskContent.generateType) {
                case GenerateType.TEXT_TO_AUDIO:
                    return (
                        <ReviewGeneratedAudioComponent item={taskContent} backButton={() => setTaskIdToReview('')} />
                    );
                case GenerateType.AUDIO_TO_TEXT:
                case GenerateType.VIDEO_TO_TEXT:
                    return (
                        <ReviewGeneratedTextComponent item={taskContent} backButton={() => setTaskIdToReview('')} />
                    );
                default:
                    return <></>;
            }
        }
    }

    return (
        <ComponentContainer>
            <TitleRow>
                <Headline3>{strings.courseEditorPage.steps.aiGeneratedContent.title}</Headline3>
                <BetaLabel />
            </TitleRow>
            {!CourseGeneratedContentIsApproved(context.course!) && showIncompleteStepsWarning && (
                <NotificationContainer>
                    <Notification
                        title={strings.courseEditorPage.generatedContentToBeReviewed.title}
                        notificationType={NotificationType.WARNING}
                        maxWidth="100%"
                    />
                </NotificationContainer>
            )}
            <TabStructureComponent tabs={[completedTab, queuedTab]} />
        </ComponentContainer>
    );
}

const NotificationContainer = styled.div`
    width: 100%;
`;

const ComponentContainer = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const TitleRow = styled.div`
    display: flex;
    align-items: center;
    gap: ${sizes.spacingRg};
`;
