import styled from 'styled-components';
import { sizes } from '../../../theme';
import { Activity, ActivityType, BuildMethod } from '../../../models/Activity';
import useGetRequest from '../../../hooks/useGetRequest';
import { EditQuestionnaireStepper } from './EditQuestionnaireStepper';
import LiveTrainingUpdateForm from './LiveTrainingUpdateForm';
import { TaskUpdateForm } from './TaskUpdateForm';
import TaskUpdateFormForTextAndImage from './taskContentUpdateForms/TaskUpdateFormForTextAndImage';
import TaskUpdateFormForVideo from './taskContentUpdateForms/TaskUpdateFormForVideo';
import { TaskUpdateFormForAudio } from './taskContentUpdateForms/TaskUpdateFormForAudio';

type ActivityUpdateComponentProps = {
    activityId: string;
    completeUpdateQuestionnaire: () => void;
    focusContent?: boolean;
};

export default function ActivityUpdateComponent({
    activityId,
    completeUpdateQuestionnaire,
    focusContent,
}: ActivityUpdateComponentProps): JSX.Element {
    const { data: selectedActivity, refetchData: refetchActivity } = useGetRequest<Activity>(
        `activities/${activityId}`,
        !activityId,
    );

    if (!selectedActivity) {
        return <></>;
    }

    switch (selectedActivity.type) {
        case ActivityType.QUESTIONNAIRE:
            return (
                <EditQuestionnaireStepper
                    activity={selectedActivity}
                    completeUpdateQuestionnaire={completeUpdateQuestionnaire}
                />
            );

        case ActivityType.LIVE_TRAINING:
            return <LiveTrainingUpdateForm activity={selectedActivity} />;

        default:
        case ActivityType.TASK:
            switch (selectedActivity.buildMethod) {
                case BuildMethod.TEXT_AND_IMAGE:
                    return (
                        <TaskUpdateFormForTextAndImage
                            activity={selectedActivity}
                            refetchActivity={refetchActivity}
                            focusContent={focusContent}
                        />
                    );
                case BuildMethod.AUDIO:
                    return <TaskUpdateFormForAudio task={selectedActivity} refetchActivity={refetchActivity} />;
                case BuildMethod.VIDEO:
                    return <TaskUpdateFormForVideo task={selectedActivity} refetchActivity={refetchActivity} />;
                default: //for tasks created before build methods were introduced
                case BuildMethod.NONE:
                    return <TaskUpdatePageContent activity={selectedActivity} refetchActivity={refetchActivity} />;
            }
    }
}

const TaskUpdatePageContent = styled(TaskUpdateForm)`
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    padding-bottom: ${sizes.spacingMd};
`;
