import { BodySmall, Headline6 } from 'components/Elements';
import { GenerateErrorReason } from 'models/Activity';
import { ErrorNotifierSlim } from 'components/notifiers/ErrorNotifierSlim';
import PrimaryButton from 'components/buttons/PrimaryButton';
import { GenerateTypeContentComponent } from '../GenerateTypeContentComponent';
import strings from '../../../../../strings/strings.json';
import { ModuleItem } from '../ModuleItem';
import { ItemCard, ModuleListItem, ModulesList } from '../SharedStyles';
import ValidateIcon from 'assets/icons/indicators/ValidateIcon';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
import SecondaryButton, { SecondaryButtonColour } from 'components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import RefreshIcon from 'assets/icons/RefreshIcon';
import React from 'react';
import useDeleteRequest from 'hooks/useDeleteRequest';
import { EditCourseContext } from 'contextProviders/EditCourseContext';
import { logError } from 'lib/debug-helpers';
import Alert from 'components/alert/Alert';
import useModifyRequest from 'hooks/useModifyRequest';
import { useNavigate } from 'react-router-dom';
import { StepId } from 'pages/edit-course/CourseEditorPage';
import PreviewIcon from 'assets/icons/controls/PreviewIcon';

type CompletedGeneratedContentTabProps = {
    moduleItems: ModuleItem[];
    setTaskIdToReview: React.Dispatch<React.SetStateAction<string>>;
};

export default function CompletedGeneratedContentTab({
    moduleItems,
    setTaskIdToReview: setShowReviewContent,
}: CompletedGeneratedContentTabProps): JSX.Element {
    const context = React.useContext(EditCourseContext);
    const navigate = useNavigate();

    const [idOfStatusToDelete, setIdOfStatusToDelete] = React.useState<string>('');
    const [idOfStatusToRetry, setIdOfStatusToRetry] = React.useState<string>('');

    const [openDeleteAlert, setOpenDeleteAlert] = React.useState<boolean>(false);
    const [deleteStatusError, setDeleteStatusError] = React.useState<boolean>(false);
    const [retryStatusError, setRetryStatusError] = React.useState<boolean>(false);

    const { deleteData, deleteInProgress } = useDeleteRequest(`generate-content-statuses/${idOfStatusToDelete}`);

    const { modifyData: generateAudio, loading: generatingAudio } = useModifyRequest(
        `generate-content-statuses/${idOfStatusToRetry}/retry`,
        'POST',
    );

    React.useEffect(() => {
        if (!idOfStatusToRetry) {
            return;
        }
        async function fetchData() {
            try {
                await generateAudio({});
                await context.refetchCourse();
                setIdOfStatusToRetry('');
            } catch (error) {
                logError(error);
                setRetryStatusError(true);
            }
        }
        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [idOfStatusToRetry]);

    const closeDeleteAlert = () => {
        setDeleteStatusError(false);
        setIdOfStatusToDelete('');
        setOpenDeleteAlert(false);
    };

    const deleteStatus = async () => {
        const response = await deleteData();

        if (response.errors && response.errors.length > 0) {
            logError(response.errors);
            setDeleteStatusError(true);
            return;
        }

        await context.refetchCourse();
        closeDeleteAlert();
    };

    if (moduleItems.length === 0) {
        return <BodySmall>{strings.courseAIGeneratedContentComponent.noContentGenerated}</BodySmall>;
    }

    if (openDeleteAlert) {
        return (
            <Alert
                alertText={strings.courseAIGeneratedContentComponent.deleteAlert.text}
                buttonText={strings.courseAIGeneratedContentComponent.deleteAlert.button.text}
                ariaButtonText={strings.courseAIGeneratedContentComponent.deleteAlert.button.aria}
                closeAlert={closeDeleteAlert}
                onSubmit={deleteStatus}
                error={
                    deleteStatusError
                        ? {
                              title: strings.courseAIGeneratedContentComponent.deleteAlert.error.title,
                              description: strings.courseAIGeneratedContentComponent.deleteAlert.error.description,
                          }
                        : undefined
                }
            />
        );
    }

    return (
        <ModulesList>
            {moduleItems.map((module, moduleIndex) => {
                return (
                    <ModuleListItem key={moduleIndex}>
                        <Headline6>{`${strings.courseAIGeneratedContentComponent.moduleTitlePrefix} ${module.moduleOrder}: ${module.moduleTitle}`}</Headline6>

                        {module.taskGenerateItems.map((taskContent, taskIndex) => {
                            const errorStatus: JSX.Element | null = taskContent.dateErrored ? (
                                <ErrorNotifierSlim description={getFriendlyError(taskContent.error)} />
                            ) : null;

                            let action: JSX.Element | null;

                            if (taskContent.dateApproved) {
                                action = (
                                    <ApprovedContainer>
                                        <ApprovedLabel>
                                            <ApprovedText>
                                                {strings.courseAIGeneratedContentComponent.approvedStatus}
                                            </ApprovedText>
                                            <ValidateIcon />
                                        </ApprovedLabel>
                                        <SecondaryButton
                                            title={strings.courseAIGeneratedContentComponent.viewButton.title}
                                            aria={strings.courseAIGeneratedContentComponent.viewButton.aria}
                                            icon={<PreviewIcon />}
                                            compact
                                            onClick={() =>
                                                navigate(`/edit-course/${context.course!.id}`, {
                                                    state: {
                                                        stepId: 'courseStructureAndContent' as StepId,
                                                        editActivityId: taskContent.taskId,
                                                    },
                                                })
                                            }
                                        />
                                    </ApprovedContainer>
                                );
                            } else if (taskContent.dateErrored) {
                                action = (
                                    <ButtonsContainer>
                                        <SecondaryButton
                                            title={strings.courseAIGeneratedContentComponent.retryButton.title}
                                            aria={strings.courseAIGeneratedContentComponent.retryButton.aria}
                                            onClick={() => {
                                                setIdOfStatusToRetry(taskContent.id);
                                            }}
                                            icon={<RefreshIcon />}
                                            submitting={deleteInProgress || generatingAudio}
                                            compact
                                        />
                                        <SecondaryButton
                                            title={strings.courseAIGeneratedContentComponent.deleteButton.title}
                                            aria={strings.courseAIGeneratedContentComponent.deleteButton.aria}
                                            onClick={() => {
                                                setIdOfStatusToDelete(taskContent.id);
                                                setOpenDeleteAlert(true);
                                            }}
                                            alternateColour={SecondaryButtonColour.DANGER}
                                            icon={<BinIcon colour={theme.errorColour} />}
                                            submitting={deleteInProgress || generatingAudio}
                                            compact
                                        />
                                    </ButtonsContainer>
                                );
                            } else {
                                action = (
                                    <ButtonsContainer>
                                        <PrimaryButton
                                            title={strings.courseAIGeneratedContentComponent.reviewButton.title}
                                            aria={strings.courseAIGeneratedContentComponent.reviewButton.aria}
                                            onClick={() => {
                                                setShowReviewContent(taskContent.id);
                                            }}
                                            submitting={deleteInProgress}
                                        />
                                    </ButtonsContainer>
                                );
                            }

                            return (
                                <ItemCard key={taskIndex}>
                                    <ItemCardContent>
                                        <TopRow>
                                            <Description>
                                                <BodySmall>{`${strings.courseAIGeneratedContentComponent.taskPrefix} ${taskContent.taskOrder}: ${taskContent.taskTitle}`}</BodySmall>
                                                <GenerateTypeContentComponent
                                                    generateType={taskContent.generateType}
                                                />
                                            </Description>
                                            {action}
                                        </TopRow>

                                        {retryStatusError && (
                                            <ErrorRow>{strings.courseAIGeneratedContentComponent.retryError}</ErrorRow>
                                        )}
                                        {errorStatus && <ErrorRow>{errorStatus}</ErrorRow>}
                                    </ItemCardContent>
                                </ItemCard>
                            );
                        })}
                    </ModuleListItem>
                );
            })}
        </ModulesList>
    );
}

const ApprovedLabel = styled.div`
    display: flex;
    gap: ${sizes.spacingSm};
`;

const ApprovedText = styled(BodySmall)`
    white-space: nowrap;
`;

const ApprovedContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    align-items: center;
`;

const ItemCardContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
    width: 100%;
`;

const TopRow = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;

    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
        gap: ${sizes.spacingSm};
    }
`;

const Description = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    flex: 80%;

    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const ButtonsContainer = styled.div`
    display: flex;
    gap: ${sizes.spacingMd};
    justify-content: end;
    flex: 20%;

    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        width: 100%;
    }
`;

const ErrorRow = styled.div`
    display: flex;
    justify-content: space-between;
`;

function getFriendlyError(error: GenerateErrorReason): string {
    let friendlyError = '';

    switch (error) {
        case GenerateErrorReason.MISSING_ENV_VAR_POLLY_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_DOCUMENTS_REGION:
        case GenerateErrorReason.MISSING_ENV_VAR_DOCUMENTS_BUCKET_NAME:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.serverError;
            break;
        case GenerateErrorReason.ACTIVITY_NOT_TASK:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.activityNotTask;
            break;
        case GenerateErrorReason.TASK_NO_CONTENT:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.taskNoContent;
            break;
        case GenerateErrorReason.CONTENT_ALREADY_GENERATED:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.contentAlreadyGenerated;
            break;
        case GenerateErrorReason.POLLY_ERROR:
        case GenerateErrorReason.POLLY_RESPONSE_NO_AUDIO:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.pollyError;
            break;
        case GenerateErrorReason.UPLOAD_AUDIO_ERROR:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.uploadAudioError;
            break;
        case GenerateErrorReason.UPDATE_STATUS_ERROR:
            friendlyError = strings.courseAIGeneratedContentComponent.generateContentErrors.updateStatusError;
            break;
        case GenerateErrorReason.NONE:
        default:
            return '';
    }

    return `${strings.courseAIGeneratedContentComponent.generateContentErrors.prefix} ${friendlyError}`;
}
