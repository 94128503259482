import * as React from 'react';
import strings from '../../strings/strings.json';
import { breakpoints, sizes, theme } from 'theme';
import useGetRequest from 'hooks/useGetRequest';
import OrganisationList from './components/OrganisationList';
import { Organisation } from 'models/Organisation';
import { UserForAdminDashboard } from 'models/UserForAdminDashboard';
import { MainSectionWithGaps } from '../../components/sections/MainSection';
import AdminHeader from '../../components/sections/AdminHeader';
import UsersList from './components/UsersList';
import styled from 'styled-components';
import { Flex, Headline2, Headline4 } from '../../components/Elements';
import PrimaryButton from '../../components/buttons/PrimaryButton';
import { useLocation, useNavigate } from 'react-router-dom';
import AddIcon from '../../assets/icons/controls/AddIcon';
import { IconSize } from '../../assets/icons/icon-sizes';
import Notification, { NotificationType } from '../../components/notifiers/Notification';
import SecondaryButton from 'components/buttons/SecondaryButton';

type State = {
    addUserSuccess: boolean;
};

export default function AdminDashboardPage(): JSX.Element {
    const navigate = useNavigate();

    const { state } = useLocation();
    const { addUserSuccess } = (state as State) ?? {};

    const {
        data: users,
        loading: usersLoading,
        errors: usersErrors,
        refetchData: refetchUsers,
    } = useGetRequest<UserForAdminDashboard[]>('admin/users');

    const usersSplitByOrganisation: Record<string, UserForAdminDashboard[]> = users
        ? users.reduce((result: Record<string, UserForAdminDashboard[]>, user) => {
              const organisation = user.organisation?.name ?? strings.adminDashboardPage.noOrganisationHeader;

              if (!result[organisation]) {
                  result[organisation] = [];
              }

              result[organisation].push(user);

              return result;
          }, {})
        : {};

    const {
        data: organisations,
        loading: loadingOrganisations,
        errors: organisationsErrors,
    } = useGetRequest<Organisation[]>('organisations');

    return (
        <>
            <AdminHeader title={strings.adminDashboardPage.bannerHeading} />
            <MainSectionWithGaps>
                {addUserSuccess && (
                    <Notification
                        notificationType={NotificationType.SUCCESS}
                        title={strings.adminDashboardPage.userAddedSuccessfullyMessage.title}
                        description={strings.adminDashboardPage.userAddedSuccessfullyMessage.description}
                        maxWidth={'60rem'}
                    />
                )}
                <UsersSectionHeader>
                    <Headline2>{strings.adminDashboardPage.pageHeading}</Headline2>
                    <ButtonsContainer>
                        <PrimaryButton
                            title={strings.adminDashboardPage.addUserButton.title}
                            aria={strings.adminDashboardPage.addUserButton.aria}
                            onClick={() => {
                                navigate('/admin/add-user');
                            }}
                            icon={<AddIcon size={IconSize.SMALL} colour={theme.textColourInverted} />}
                        />
                        <SecondaryButton
                            title={strings.adminDashboardPage.notifyUsersButton.title}
                            aria={strings.adminDashboardPage.notifyUsersButton.aria}
                            onClick={() => navigate('/admin/notify-users')}
                        />
                    </ButtonsContainer>
                </UsersSectionHeader>
                {Object.keys(usersSplitByOrganisation).map((organisation) => {
                    return (
                        <div key={organisation}>
                            <OrganisationHeadline>{organisation}</OrganisationHeadline>
                            <UsersList
                                users={usersSplitByOrganisation[organisation]}
                                loading={usersLoading}
                                errors={usersErrors}
                                refetchUsers={refetchUsers}
                            />
                        </div>
                    );
                })}
                <OrganisationList
                    organisations={organisations || []}
                    loading={loadingOrganisations}
                    errors={organisationsErrors}
                />
            </MainSectionWithGaps>
        </>
    );
}

const UsersSectionHeader = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;

    @media (max-width: ${breakpoints.sm}) {
        flex-direction: column;
        gap: ${sizes.spacingMd};
    }
`;

const OrganisationHeadline = styled(Headline4)`
    margin-bottom: ${sizes.spacingMd};
`;

const ButtonsContainer = styled(Flex)`
    gap: ${sizes.spacingMd};
`;
