import SubpageHeader, { BackButtonDetails } from '../../../components/sections/SubpageHeader';
import strings from '../../../strings/strings.json';
import { MainSectionWithGaps } from '../../../components/sections/MainSection';
import React, { useContext } from 'react';
import styled from 'styled-components';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { logError } from '../../../lib/debug-helpers';
import useUploadRequest from '../../../hooks/useUploadRequest';
import Notification, { LinkToHelp, NotificationType } from '../../../components/notifiers/Notification';
import useModifyRequest from '../../../hooks/useModifyRequest';
import { Card } from '../../../components/Elements';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import { AuthContext } from 'contextProviders/AuthContext';
import FileUpload from '../../../components/fileUpload/FileUpload';
import { FileMetaData, FileType } from '../../../components/fileUpload/strategies/strategies';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { theme } from 'theme';

export default function LogoUploadPage(): JSX.Element {
    const { upload, uploading } = useUploadRequest();
    const navigate = useNavigate();
    const { userData, refreshUserData } = useContext(AuthContext);

    const { modifyData: attachLogo, loading: createLoading } = useModifyRequest(
        `organisations/${userData?.organisation?.id}/logo`,
        'PUT',
    );

    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [fileMetaData, setFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [fileValid, setFileValid] = React.useState<boolean>(false);
    const [fileMetaDataValid, setFileMetaDataValid] = React.useState<boolean>(false);
    const [error, setError] = React.useState<string | null>(null);

    const backButtonDetails: BackButtonDetails = {
        title: strings.OALogoUploadPage.backButton.title,
        aria: strings.OALogoUploadPage.backButton.aria,
        href: '/organisation/overview',
    };

    function onSelectFile(file: File | undefined) {
        setTempFileFromLocal(file);
        setError(null);
    }

    async function uploadFile() {
        setError(null);

        if (!tempFileFromLocal) {
            logError(strings.OrganisationOverviewPage.noFileSelectedErrorText);
            setError(strings.OrganisationOverviewPage.noFileSelectedErrorText);
            return;
        }

        const uploadResponse = await upload(tempFileFromLocal);

        if (uploadResponse.errors && uploadResponse.errors.length > 0) {
            logError(uploadResponse.errors);
            setError(uploadResponse.errors[0].code);
            return;
        }

        if (!uploadResponse.value) {
            logError('Get presigned URL failed');
            setError('get presigned URL failed');
            return;
        }

        const response = await attachLogo({
            filePath: uploadResponse.value.s3Key,
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            setError(errors[0].code);
            return;
        }

        refreshUserData();
        navigate('/organisation/overview');
    }

    const linkToLogoInfo: LinkToHelp = {
        text: strings.OALogoUploadPage.brandingUploadGuidelines.moreInfoLinkText,
        aria: strings.OALogoUploadPage.brandingUploadGuidelines.moreInfoLinkAria,
        url: 'https://www.wix.com/blog/logo-size',
    };

    return (
        <>
            <SubpageHeader
                title={strings.OALogoUploadPage.title}
                backButtonDetails={backButtonDetails}
                shadedBackground
            />
            <MainSectionWithGaps>
                <Notification
                    maxWidth="1240px"
                    notificationType={NotificationType.INFO}
                    title={strings.OALogoUploadPage.brandingUploadGuidelines.title}
                    description={strings.OALogoUploadPage.brandingUploadGuidelines.description}
                    linkToHelp={linkToLogoInfo}
                />
                <Card>
                    <FileUpload
                        localFile={tempFileFromLocal}
                        setLocalFile={onSelectFile}
                        fileMetaData={fileMetaData}
                        setFileMetaData={setFileMetaData}
                        fileValid={fileValid}
                        setFileValid={setFileValid}
                        fileMetaDataValid={fileMetaDataValid}
                        setFileMetaDataValid={setFileMetaDataValid}
                        chosenFileType={FileType.LOGO}
                    />
                </Card>
                {error && (
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.OALogoUploadPage.errorTitle}
                        description={error ?? ''}
                        maxWidth={'100%'}
                    />
                )}
                <AddFileButtonRow>
                    <PrimaryButton
                        title={strings.OALogoUploadPage.addLogoButton.title}
                        aria={strings.OALogoUploadPage.addLogoButton.aria}
                        onClick={uploadFile}
                        disabled={uploading || createLoading}
                        icon={
                            uploading || createLoading ? (
                                <Spinner />
                            ) : (
                                <UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />
                            )
                        }
                    />
                </AddFileButtonRow>
            </MainSectionWithGaps>
        </>
    );
}

const AddFileButtonRow = styled.div`
    display: flex;
    justify-content: end;
`;
