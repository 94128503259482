import SubpageHeader, { BackButtonDetails } from '../../../components/sections/SubpageHeader';
import strings from '../../../strings/strings.json';
import { MainSectionWithGaps } from '../../../components/sections/MainSection';
import React from 'react';
import {
    ACCEPTED_AUDIO_MIME_TYPES,
    ACCEPTED_IMAGE_MIME_TYPES,
    ACCEPTED_VIDEO_MIME_TYPES,
} from '../../../lib/_api-helpers';
import styled from 'styled-components';
import PrimaryButton from '../../../components/buttons/PrimaryButton';
import { logError } from '../../../lib/debug-helpers';
import useUploadRequest from '../../../hooks/useUploadRequest';
import Notification, { NotificationType } from '../../../components/notifiers/Notification';
import useModifyRequest from '../../../hooks/useModifyRequest';
import { DocumentRequest } from '../../../models/inputModels/DocumentRequest';
import { Card } from '../../../components/Elements';
import { FileDocument, FileDocumentType } from '../../../models/FileDocument';
import { useNavigate } from 'react-router-dom';
import Spinner from '../../../components/Spinner';
import FileUpload from '../../../components/fileUpload/FileUpload';
import { FileMetaData } from '../../../components/fileUpload/strategies/strategies';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';
import { theme } from 'theme';

export default function FileUploadPage(): JSX.Element {
    const { upload, uploading } = useUploadRequest();
    const navigate = useNavigate();

    const { modifyData: createDocument, loading: createLoading } = useModifyRequest('documents', 'POST');

    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [fileValid, setFileValid] = React.useState<boolean>(false);
    const [fileMetaDataValid, setFileMetaDataValid] = React.useState<boolean>(false);
    const [fileMetaData, setFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [error, setError] = React.useState<string | null>(null);

    const backButtonDetails: BackButtonDetails = {
        title: strings.fileUploadPage.backButton.title,
        aria: strings.fileUploadPage.backButton.aria,
        href: '/organisation/files',
    };

    async function uploadFile() {
        setError(null);

        if (!tempFileFromLocal) {
            logError(strings.fileUploadPage.noFileText);
            setError(strings.fileUploadPage.noFileText);
            return;
        }

        if (!fileValid) return;
        if (!fileMetaDataValid) return;

        const uploadResponse = await upload(tempFileFromLocal);

        if (uploadResponse.errors && uploadResponse.errors.length > 0) {
            logError(uploadResponse.errors);
            setError(uploadResponse.errors[0].code);
            return;
        }

        if (!uploadResponse.value) {
            logError('Get presigned URL failed');
            setError('get presigned URL failed');
            return;
        }

        let documentType: FileDocumentType = FileDocumentType.IMAGE;

        if (ACCEPTED_IMAGE_MIME_TYPES.includes(tempFileFromLocal.type)) {
            documentType = FileDocumentType.IMAGE;
        } else if (ACCEPTED_VIDEO_MIME_TYPES.includes(tempFileFromLocal.type)) {
            documentType = FileDocumentType.VIDEO;
        } else if (ACCEPTED_AUDIO_MIME_TYPES.includes(tempFileFromLocal.type)) {
            documentType = FileDocumentType.AUDIO;
        }

        const response = await createDocument<DocumentRequest, FileDocument>({
            fileName: uploadResponse.value.filename,
            filePath: uploadResponse.value.s3Key,
            type: documentType,
            videoTranscript: fileMetaData?.videoTranscript,
            imageAltText: fileMetaData?.imageAltText,
        });

        const { errors } = response;

        if (errors) {
            logError(errors);
            setError(errors[0].code);
            return;
        }

        navigate(`/organisation/files`, { state: { newFileId: response.value?.id } });
    }

    return (
        <>
            <SubpageHeader
                title={strings.fileUploadPage.title}
                backButtonDetails={backButtonDetails}
                shadedBackground
            />
            <MainSectionWithGaps>
                <Card>
                    <FileUpload
                        localFile={tempFileFromLocal}
                        setLocalFile={(newFile: File | undefined) => {
                            setTempFileFromLocal(newFile);
                        }}
                        fileMetaData={fileMetaData}
                        setFileMetaData={(newFileMetaData: FileMetaData | undefined) => {
                            setFileMetaData(newFileMetaData);
                        }}
                        fileValid={fileValid}
                        setFileValid={(fileValid: boolean) => {
                            setFileValid(fileValid);
                        }}
                        fileMetaDataValid={fileMetaDataValid}
                        setFileMetaDataValid={(fileMetaDataValid: boolean) => {
                            setFileMetaDataValid(fileMetaDataValid);
                        }}
                        chosenFileType={undefined}
                    />
                </Card>
                {error && (
                    <Notification
                        notificationType={NotificationType.DANGER}
                        title={strings.fileUploadPage.errorTitle}
                        description={error ?? ''}
                        maxWidth={'100%'}
                    />
                )}
                <AddFileButtonRow>
                    <PrimaryButton
                        title={strings.selectFileForm.uploadFileButtonTitle}
                        aria={strings.selectFileForm.uploadFileButtonAria}
                        onClick={uploadFile}
                        disabled={uploading || createLoading}
                        icon={
                            uploading || createLoading ? (
                                <Spinner />
                            ) : (
                                <UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />
                            )
                        }
                    />
                </AddFileButtonRow>
            </MainSectionWithGaps>
        </>
    );
}

const AddFileButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;
