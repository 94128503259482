import * as React from 'react';
import styled from 'styled-components';
import strings from '../../../strings/strings.json';
import { BodyRegular, Flex, Headline5 } from '../../../components/Elements';
import { Activity, ActivityType } from '../../../models/Activity';
import { breakpoints, sizes, theme } from '../../../theme';
import useDeleteRequest from '../../../hooks/useDeleteRequest';
import { publish } from '../../../lib/events';
import { logError } from '../../../lib/debug-helpers';
import Alert from '../../../components/alert/Alert';
import SecondaryButton, { SecondaryButtonColour } from '../../../components/buttons/SecondaryButton';
import BinIcon from 'assets/icons/controls/BinIcon';
import EditIcon from 'assets/icons/controls/EditIcon';
import ActivityTypeLabel from './ActivityTypeLabel';

type ActivityCardProps = {
    activity: Activity;
    showEditControls?: boolean;
    isDragging: boolean;
    refetchCourse?: () => Promise<void>;
    editActivityClick?: (activityId: string) => void;
};

export default function ActivityCard({
    activity,
    showEditControls = true,
    isDragging,
    refetchCourse,
    editActivityClick,
}: ActivityCardProps): JSX.Element {
    const { deleteData, deleteInProgress } = useDeleteRequest(`activities/${activity?.id}`);

    const [alertOpen, setAlertOpen] = React.useState(false);

    const toggleAlert = () => {
        setAlertOpen(!alertOpen);
    };

    const onDelete = async () => {
        const response = await deleteData();

        if (response.errors) {
            logError(response.errors);
            return;
        }

        publish('ACTIVITY_DELETED', { id: activity.id });

        if (!!refetchCourse) await refetchCourse();

        toggleAlert();
    };

    const generateSubtitle = () => {
        if (activity.type === ActivityType.QUESTIONNAIRE) {
            return (
                <Subtitle>
                    <BodyRegular>
                        {activity.questions.length} {strings.courseContentPage.questions}
                    </BodyRegular>
                </Subtitle>
            );
        }

        if (activity.type === ActivityType.LIVE_TRAINING) {
            return (
                <Subtitle>
                    <BodyRegular>
                        {activity.durationInMins} {strings.courseContentPage.mins}
                    </BodyRegular>
                </Subtitle>
            );
        }
    };

    return (
        <>
            <Grid $isDragging={isDragging}>
                <Title>
                    <TitleAndLabelContainer>
                        <Headline5>{activity.title}</Headline5>
                    </TitleAndLabelContainer>
                </Title>
                {showEditControls && (
                    <ControlContainer>
                        <LabelAndDurationContainer>
                            <ActivityTypeLabel activityType={activity.type} />
                            {generateSubtitle()}
                        </LabelAndDurationContainer>
                        <ButtonContainer>
                            <SecondaryButton
                                title={strings.courseContentPage.editActivityButton}
                                aria={strings.courseContentPage.editActivityButtonAria}
                                onClick={editActivityClick}
                                icon={<EditIcon />}
                            />
                            <SecondaryButton
                                onClick={toggleAlert}
                                disabled={deleteInProgress}
                                aria={strings.courseContentPage.removeActivityButtonAria}
                                title={strings.courseContentPage.removeActivityButton}
                                icon={<BinIcon />}
                                alternateColour={SecondaryButtonColour.ALTERNATE}
                            />
                        </ButtonContainer>
                    </ControlContainer>
                )}
            </Grid>
            {alertOpen && (
                <Alert
                    buttonText={strings.courseContentPage.alertButton}
                    ariaButtonText={strings.courseContentPage.alertButtonAria}
                    alertText={strings.courseContentPage.alertText}
                    closeAlert={toggleAlert}
                    onSubmit={onDelete}
                />
            )}
        </>
    );
}

const Grid = styled.div<{ $isDragging: boolean }>`
    padding: ${sizes.spacingMd};
    border: ${({ $isDragging }) => {
        return $isDragging ? `2px solid ${theme.primaryButtonBorderColour}` : `1px solid ${theme.cardBorder}`;
    }};
    box-shadow: 2px 4px 6px rgba(0, 0, 0, 0.1);
    border-radius: ${sizes.spacingSm};

    display: grid;
    grid-template-areas: 'a' 'b';
    grid-template-columns: 1fr;
    column-gap: ${sizes.spacingMd};
    align-items: center;

    background-color: ${({ $isDragging }) => {
        return $isDragging ? theme.primaryButtonFocusBackgroundColour : 'none';
    }};

    @media (max-width: ${breakpoints.md}) {
        grid-template-areas: 'a' 'b';
        grid-template-columns: unset;
        row-gap: ${sizes.spacingSm};
        column-gap: unset;
    }
`;

const Title = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingSm};
    overflow: hidden;
    margin-bottom: ${sizes.spacingSm};
`;

const TitleAndLabelContainer = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: unset;
        justify-content: unset;
        gap: ${sizes.spacingSm};
    }
`;

const ControlContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.lg}) {
        flex-direction: column;
        align-items: unset;
    }
`;

const LabelAndDurationContainer = styled(Flex)`
    gap: ${sizes.spacingSm};
    @media (max-width: ${breakpoints.lg}) {
        justify-content: space-between;
    }
`;

const ButtonContainer = styled(Flex)`
    gap: ${sizes.spacingMd};
    @media (max-width: ${breakpoints.lg}) {
        justify-content: space-between;
    }
    @media (max-width: ${breakpoints.md}) {
        flex-direction: column;
    }
`;

const Subtitle = styled.div`
    display: flex;
    flex-direction: column;
`;
