import styled from 'styled-components';
import { FONTSIZE, sizes, theme, breakpoints } from '../../theme';
// @ts-ignore
import WarningIcon from 'assets/icons/alert-icons/alert-warning-red-icon.svg';

// misc

export const formTheme = {
    formCardWidth: '700px',
    smallFormCardWidth: '580px',
};

export const FormCard = styled.form`
    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
    width: ${formTheme.formCardWidth};
    padding: ${sizes.spacingMd} ${sizes.spacingMd} ${sizes.spacingLg};
    background-color: ${theme.cardBackground};
    border-radius: ${sizes.borderRadiusLg};
    border: 1px solid ${theme.cardBorder};
    box-shadow: 2px 4px 6px 0 rgba(0, 0, 0, 0.1);
`;

export const FormCardWithGaps = styled(FormCard)`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

export const FormFieldContainer = styled.div`
    padding-bottom: ${sizes.spacingMd};
`;

export const FormLabel = styled.label`
    height: ${sizes.spacingRg};
    font-size: ${FONTSIZE.BodyRegular};
    color: ${theme.textColour};
    font-weight: 600;
`;

export const ErrorMessage = styled.p<{ $bold?: boolean }>`
    color: ${theme.errorColour};
    margin: ${sizes.spacingSm} 0;
    font-size: ${FONTSIZE.BodySmall};
    font-weight: ${({ $bold }) => ($bold ? 'bold' : 'inherit')};
`;

// text

export const FormTextInput = styled.input<{ $error?: boolean; width?: string }>`
    height: 48px;
    width: ${({ width }) => {
        return width ? width : '100%';
    }};
    display: block;
    font-size: ${FONTSIZE.BodyRegular};
    border-radius: ${sizes.borderRadiusMd};
    border-style: solid;
    border-width: 1px;
    border-color: ${({ $error }) => ($error ? theme.errorColour : theme.inputBorderColour)};
    background: ${({ $error }) =>
        $error ? `url(${WarningIcon}) no-repeat calc(100% - 10px) ${theme.inputBackgroundError}` : null};
    font-family: ${theme.fontFamilyMain};
    margin-top: 7px;
    text-indent: 0;
    padding: 16px;
    &:focus {
        outline: none;
        border-style: solid;
        border-width: 3px;
        border-color: ${({ $error }) => ($error ? theme.errorColour : theme.inputActiveColour)};
    }
`;

export const FormTextAreaInput = styled.textarea<{ $error?: boolean; $height?: number }>`
    width: 100%;
    min-height: ${({ $height }) => ($height ? `${$height}px` : '128px')};
    text-indent: 0;
    padding: 16px;
    min-width: 100%;
    max-width: 100%;
    font-size: ${FONTSIZE.BodyRegular};
    border-radius: 4px;
    border-style: solid;
    border-width: 1px;
    border-color: ${({ $error }) => ($error ? theme.errorColour : theme.inputBorderColour)};
    background: ${({ $error }) => ($error ? `url(${WarningIcon}) no-repeat calc(100% - 10px)` : null)};
    font-family: ${theme.fontFamilyMain};
    margin-top: 7px;
    &:focus {
        outline: none;
        border-style: solid;
        border-width: 3px;
        border-color: ${({ $error }) => ($error ? theme.errorColour : theme.inputActiveColour)};
    }
`;
