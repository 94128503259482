import { BodyRegular, BodySmall, Headline4, IconLinkButtonText, TertiaryButton } from 'components/Elements';
import React from 'react';
import styled from 'styled-components';
import { breakpoints, sizes, theme } from 'theme';
// @ts-ignore
import { ReactComponent as ArrowBack } from 'assets/icons/nav-icons/arrow-back.svg';
import strings from '../../../strings/strings.json';
import Skeleton from 'react-loading-skeleton';
import PrimaryButton from 'components/buttons/PrimaryButton';
import useKeyboardTrap from 'hooks/useKeyboardTrap';
import Notification, { NotificationType } from 'components/notifiers/Notification';
import ArrowDownIcon from 'assets/icons/controls/ArrowDownIcon';
import ArrowUpIcon from 'assets/icons/controls/ArrowUpIcon';
import FileUpload from 'components/fileUpload/FileUpload';
import { FileMetaData, FileType, STRATEGIES } from 'components/fileUpload/strategies/strategies';
import { FileDocument } from 'models/FileDocument';
import UploadIcon from 'assets/icons/controls/UploadIcon';
import { IconSize } from 'assets/icons/icon-sizes';

type SelectFileFormProps = {
    organisationName: string;
    filesFromProvider: FileDocument[];
    loadingFiles: boolean;
    setFileFromLocal: (newFile: File | null | undefined) => void;
    setFileFromProvider: (file: FileDocument) => void;
    setFileFromLocalMetaData: (newFileMetaData: FileMetaData | undefined) => void;
    closeModal: () => void;
    chosenFileType: FileType;
};

export default function SelectFileForm({
    organisationName,
    filesFromProvider,
    loadingFiles,
    setFileFromLocal,
    setFileFromProvider,
    setFileFromLocalMetaData,
    closeModal,
    chosenFileType,
}: SelectFileFormProps): JSX.Element {
    const [showMoreFiles, setShowMoreFiles] = React.useState(false);
    const [tempFileFromLocal, setTempFileFromLocal] = React.useState<File | undefined>();
    const [localFileValid, setLocalFileValid] = React.useState<boolean>(false);
    const [localFileMetaData, setLocalFileMetaData] = React.useState<FileMetaData | undefined>(undefined);
    const [localFileMetaDataValid, setLocalFileMetaDataValid] = React.useState<boolean>(false);
    const [tempFileFromProvider, setTempFileFromProvider] = React.useState<FileDocument>();
    const [showError, setShowError] = React.useState<boolean>(false);

    const { ref } = useKeyboardTrap(closeModal);

    React.useEffect(() => {
        setShowError(false);
    }, [tempFileFromLocal, tempFileFromProvider]);

    function onSelectProviderFile(file: FileDocument) {
        setTempFileFromProvider(file);
        setTempFileFromLocal(undefined);
    }

    function onUploadFile(file: File | undefined) {
        setTempFileFromProvider(undefined);
        setTempFileFromLocal(file);
    }

    function onClickAddFile() {
        if (!tempFileFromLocal && !tempFileFromProvider) {
            setShowError(true);
        } else {
            if (tempFileFromLocal) {
                if (!localFileValid) return;
                if (!localFileMetaDataValid) return;

                setFileFromLocal(tempFileFromLocal);
                setFileFromLocalMetaData(localFileMetaData);
            } else if (tempFileFromProvider) {
                setFileFromProvider(tempFileFromProvider);
            }

            closeModal();
        }
    }

    function onClickShowFiles() {
        if (showMoreFiles) {
            setShowMoreFiles(false);
        } else {
            setShowMoreFiles(true);
        }
    }

    const minimumNumberOfFilesToDisplay = 2;

    const filesToDisplay = showMoreFiles
        ? filesFromProvider
        : filesFromProvider.slice(0, minimumNumberOfFilesToDisplay);

    const errorDescription =
        filesToDisplay.length > 0
            ? strings.selectFileForm.errorDescriptionIfList
            : strings.selectFileForm.errorDescription;

    return (
        <Form ref={ref}>
            <SelectFileRow>
                <BackButtonContainer>
                    <BackButton
                        onClick={closeModal}
                        aria-label={strings.selectFileForm.backButtonAria}
                        type={'button'}
                    >
                        <ArrowBack />
                    </BackButton>
                </BackButtonContainer>
                <Headline4>{strings.selectFileForm.title}</Headline4>
            </SelectFileRow>

            {loadingFiles ? (
                <Skeleton height={227} />
            ) : (
                filesFromProvider.length > 0 && (
                    <ProviderFileContent>
                        {organisationName && (
                            <BodyRegular $bold={true}>
                                {strings.selectFileForm.uploadedBy} {organisationName}
                            </BodyRegular>
                        )}

                        <FileList>
                            {filesToDisplay.map((v) => (
                                <FileCard key={v.id}>
                                    <FileRadioInput
                                        id={v.id}
                                        type={'radio'}
                                        checked={v.id === tempFileFromProvider?.id}
                                        onChange={() => onSelectProviderFile(v)}
                                        onClick={() => onSelectProviderFile(v)}
                                    />
                                    <FileLabel htmlFor={v.id}>
                                        <FileIconAndDescription>
                                            <div>{STRATEGIES.find((x) => x.fileType === chosenFileType)?.icon}</div>
                                            <FileDescription>
                                                <BodySmall $bold={true}>{v.filename}</BodySmall>
                                                <BodySmall>{new Date(v.dateUpdated).toLocaleDateString()}</BodySmall>
                                            </FileDescription>
                                        </FileIconAndDescription>
                                    </FileLabel>
                                </FileCard>
                            ))}
                        </FileList>

                        {filesFromProvider.length > minimumNumberOfFilesToDisplay && (
                            <ShowFilesButtonRow>
                                <TertiaryButton onClick={onClickShowFiles} type="button">
                                    <ShowFilesButtonContent>
                                        <IconLinkButtonText>
                                            <ShowFilesText>
                                                {showMoreFiles
                                                    ? strings.selectFileForm.showFewer
                                                    : strings.selectFileForm.showMore}
                                            </ShowFilesText>
                                        </IconLinkButtonText>
                                        {showMoreFiles ? <ArrowUpIcon /> : <ArrowDownIcon />}
                                    </ShowFilesButtonContent>
                                </TertiaryButton>
                            </ShowFilesButtonRow>
                        )}
                    </ProviderFileContent>
                )
            )}

            <FileUpload
                localFile={tempFileFromLocal}
                setLocalFile={onUploadFile}
                fileMetaData={localFileMetaData}
                setFileMetaData={(newFileMetaData) => {
                    setLocalFileMetaData(newFileMetaData);
                }}
                fileValid={localFileValid}
                setFileValid={(fileValid) => {
                    setLocalFileValid(fileValid);
                }}
                fileMetaDataValid={localFileMetaDataValid}
                setFileMetaDataValid={(fileValid) => {
                    setLocalFileMetaDataValid(fileValid);
                }}
                chosenFileType={chosenFileType}
            />
            {showError && (
                <Notification
                    notificationType={NotificationType.DANGER}
                    title={strings.selectFileForm.errorTitle}
                    description={errorDescription}
                    maxWidth={'100%'}
                />
            )}
            <AddFileButtonRow>
                <PrimaryButton
                    title={strings.selectFileForm.uploadFileButtonTitle}
                    aria={strings.selectFileForm.uploadFileButtonAria}
                    onClick={onClickAddFile}
                    icon={<UploadIcon colour={theme.cardBackground} size={IconSize.SMALL} />}
                />
            </AddFileButtonRow>
        </Form>
    );
}

const ShowFilesText = styled(BodySmall)`
    font-weight: 700;
`;

const AddFileButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;

const Form = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingLg};
    margin-bottom: ${sizes.spacingMd};
    width: 700px;
    @media (max-width: ${breakpoints.md}) {
        width: 100%;
    }
`;

const ShowFilesButtonRow = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: end;
`;

const ShowFilesButtonContent = styled.div`
    display: flex;
    flex-direction: row;
    gap: ${sizes.spacingSm};
`;

const SelectFileRow = styled.div`
    display: flex;
    align-items: center;
    height: 32px;
    margin-top: ${sizes.spacingMd};
`;

const BackButtonContainer = styled.div`
    width: 37.5%;

    @media (max-width: ${breakpoints.sm}) {
        width: 32%;
    }
`;

const BackButton = styled(TertiaryButton)`
    display: flex;
    align-items: center;
`;

const FileList = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;

const FileCard = styled.div`
    position: relative;
`;

const FileLabel = styled.label`
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    padding: ${sizes.spacingSm} ${sizes.spacingMd};

    overflow: hidden;

    border: 1px solid ${theme.cardBorder};
    border-radius: ${sizes.borderRadiusMd};
    width: 100%;
`;

const FileRadioInput = styled.input`
    position: absolute;
    right: ${sizes.spacingMd};
    margin: auto 0;
    top: 0;
    bottom: 0;

    width: 32px;
    height: 32px;

    &:focus ~ ${FileLabel} {
        background-color: ${theme.inputRadioButton.focusBackgroundColour};
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:checked {
        accent-color: ${theme.inputRadioButton.activeRadioColour};
    }
    &:checked ~ ${FileLabel} {
        border: 2px solid ${theme.inputRadioButton.checkedBorderColour};
    }
    &:hover ~ ${FileLabel} {
        background-color: ${theme.inputRadioButton.hoverBackgroundColour};
    }
`;

export const FileDescription = styled.div`
    display: flex;
    flex-direction: column;
    word-wrap: break-word;
    max-width: 500px;
    @media (max-width: ${breakpoints.sm}) {
        max-width: 200px;
    }
`;

export const FileIconAndDescription = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: ${sizes.spacingMd};
`;

const ProviderFileContent = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${sizes.spacingMd};
`;
